.index-1 {
  z-index: 1 !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-105 {
  margin-top: 105px !important;
}

.mt-110 {
  margin-top: 110px !important;
}

.mt-115 {
  margin-top: 115px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mt-125 {
  margin-top: 125px !important;
}

.mt-130 {
  margin-top: 130px !important;
}

.mt-135 {
  margin-top: 135px !important;
}

.mt-140 {
  margin-top: 140px !important;
}

.mt-145 {
  margin-top: 145px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mt-155 {
  margin-top: 155px !important;
}

.mt-160 {
  margin-top: 160px !important;
}

.mt-165 {
  margin-top: 165px !important;
}

.mt-170 {
  margin-top: 170px !important;
}

.mt-175 {
  margin-top: 175px !important;
}

.mt-180 {
  margin-top: 180px !important;
}

.mt-185 {
  margin-top: 185px !important;
}

.mt-190 {
  margin-top: 190px !important;
}

.mt-195 {
  margin-top: 195px !important;
}

.mt-200 {
  margin-top: 200px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mr-105 {
  margin-right: 105px !important;
}

.mr-110 {
  margin-right: 110px !important;
}

.mr-115 {
  margin-right: 115px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.mr-125 {
  margin-right: 125px !important;
}

.mr-130 {
  margin-right: 130px !important;
}

.mr-135 {
  margin-right: 135px !important;
}

.mr-140 {
  margin-right: 140px !important;
}

.mr-145 {
  margin-right: 145px !important;
}

.mr-150 {
  margin-right: 150px !important;
}

.mr-155 {
  margin-right: 155px !important;
}

.mr-160 {
  margin-right: 160px !important;
}

.mr-165 {
  margin-right: 165px !important;
}

.mr-170 {
  margin-right: 170px !important;
}

.mr-175 {
  margin-right: 175px !important;
}

.mr-180 {
  margin-right: 180px !important;
}

.mr-185 {
  margin-right: 185px !important;
}

.mr-190 {
  margin-right: 190px !important;
}

.mr-195 {
  margin-right: 195px !important;
}

.mr-200 {
  margin-right: 200px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-105 {
  margin-bottom: 105px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.mb-115 {
  margin-bottom: 115px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mb-125 {
  margin-bottom: 125px !important;
}

.mb-130 {
  margin-bottom: 130px !important;
}

.mb-135 {
  margin-bottom: 135px !important;
}

.mb-140 {
  margin-bottom: 140px !important;
}

.mb-145 {
  margin-bottom: 145px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mb-155 {
  margin-bottom: 155px !important;
}

.mb-160 {
  margin-bottom: 160px !important;
}

.mb-165 {
  margin-bottom: 165px !important;
}

.mb-170 {
  margin-bottom: 170px !important;
}

.mb-175 {
  margin-bottom: 175px !important;
}

.mb-180 {
  margin-bottom: 180px !important;
}

.mb-185 {
  margin-bottom: 185px !important;
}

.mb-190 {
  margin-bottom: 190px !important;
}

.mb-195 {
  margin-bottom: 195px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.ml-105 {
  margin-left: 105px !important;
}

.ml-110 {
  margin-left: 110px !important;
}

.ml-115 {
  margin-left: 115px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.ml-125 {
  margin-left: 125px !important;
}

.ml-130 {
  margin-left: 130px !important;
}

.ml-135 {
  margin-left: 135px !important;
}

.ml-140 {
  margin-left: 140px !important;
}

.ml-145 {
  margin-left: 145px !important;
}

.ml-150 {
  margin-left: 150px !important;
}

.ml-155 {
  margin-left: 155px !important;
}

.ml-160 {
  margin-left: 160px !important;
}

.ml-165 {
  margin-left: 165px !important;
}

.ml-170 {
  margin-left: 170px !important;
}

.ml-175 {
  margin-left: 175px !important;
}

.ml-180 {
  margin-left: 180px !important;
}

.ml-185 {
  margin-left: 185px !important;
}

.ml-190 {
  margin-left: 190px !important;
}

.ml-195 {
  margin-left: 195px !important;
}

.ml-200 {
  margin-left: 200px !important;
}

.m-0 {
  margin: 0px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.m-55 {
  margin: 55px !important;
}

.m-60 {
  margin: 60px !important;
}

.m-65 {
  margin: 65px !important;
}

.m-70 {
  margin: 70px !important;
}

.m-75 {
  margin: 75px !important;
}

.m-80 {
  margin: 80px !important;
}

.m-85 {
  margin: 85px !important;
}

.m-90 {
  margin: 90px !important;
}

.m-95 {
  margin: 95px !important;
}

.m-100 {
  margin: 100px !important;
}

.m-105 {
  margin: 105px !important;
}

.m-110 {
  margin: 110px !important;
}

.m-115 {
  margin: 115px !important;
}

.m-120 {
  margin: 120px !important;
}

.m-125 {
  margin: 125px !important;
}

.m-130 {
  margin: 130px !important;
}

.m-135 {
  margin: 135px !important;
}

.m-140 {
  margin: 140px !important;
}

.m-145 {
  margin: 145px !important;
}

.m-150 {
  margin: 150px !important;
}

.m-155 {
  margin: 155px !important;
}

.m-160 {
  margin: 160px !important;
}

.m-165 {
  margin: 165px !important;
}

.m-170 {
  margin: 170px !important;
}

.m-175 {
  margin: 175px !important;
}

.m-180 {
  margin: 180px !important;
}

.m-185 {
  margin: 185px !important;
}

.m-190 {
  margin: 190px !important;
}

.m-195 {
  margin: 195px !important;
}

.m-200 {
  margin: 200px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-105 {
  padding-top: 105px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.pt-115 {
  padding-top: 115px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pt-125 {
  padding-top: 125px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

.pt-135 {
  padding-top: 135px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pt-145 {
  padding-top: 145px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pt-155 {
  padding-top: 155px !important;
}

.pt-160 {
  padding-top: 160px !important;
}

.pt-165 {
  padding-top: 165px !important;
}

.pt-170 {
  padding-top: 170px !important;
}

.pt-175 {
  padding-top: 175px !important;
}

.pt-180 {
  padding-top: 180px !important;
}

.pt-185 {
  padding-top: 185px !important;
}

.pt-190 {
  padding-top: 190px !important;
}

.pt-195 {
  padding-top: 195px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.pr-105 {
  padding-right: 105px !important;
}

.pr-110 {
  padding-right: 110px !important;
}

.pr-115 {
  padding-right: 115px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.pr-125 {
  padding-right: 125px !important;
}

.pr-130 {
  padding-right: 130px !important;
}

.pr-135 {
  padding-right: 135px !important;
}

.pr-140 {
  padding-right: 140px !important;
}

.pr-145 {
  padding-right: 145px !important;
}

.pr-150 {
  padding-right: 150px !important;
}

.pr-155 {
  padding-right: 155px !important;
}

.pr-160 {
  padding-right: 160px !important;
}

.pr-165 {
  padding-right: 165px !important;
}

.pr-170 {
  padding-right: 170px !important;
}

.pr-175 {
  padding-right: 175px !important;
}

.pr-180 {
  padding-right: 180px !important;
}

.pr-185 {
  padding-right: 185px !important;
}

.pr-190 {
  padding-right: 190px !important;
}

.pr-195 {
  padding-right: 195px !important;
}

.pr-200 {
  padding-right: 200px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-105 {
  padding-bottom: 105px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.pb-115 {
  padding-bottom: 115px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pb-125 {
  padding-bottom: 125px !important;
}

.pb-130 {
  padding-bottom: 130px !important;
}

.pb-135 {
  padding-bottom: 135px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.pb-145 {
  padding-bottom: 145px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.pb-155 {
  padding-bottom: 155px !important;
}

.pb-160 {
  padding-bottom: 160px !important;
}

.pb-165 {
  padding-bottom: 165px !important;
}

.pb-170 {
  padding-bottom: 170px !important;
}

.pb-175 {
  padding-bottom: 175px !important;
}

.pb-180 {
  padding-bottom: 180px !important;
}

.pb-185 {
  padding-bottom: 185px !important;
}

.pb-190 {
  padding-bottom: 190px !important;
}

.pb-195 {
  padding-bottom: 195px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pl-105 {
  padding-left: 105px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.pl-115 {
  padding-left: 115px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.pl-125 {
  padding-left: 125px !important;
}

.pl-130 {
  padding-left: 130px !important;
}

.pl-135 {
  padding-left: 135px !important;
}

.pl-140 {
  padding-left: 140px !important;
}

.pl-145 {
  padding-left: 145px !important;
}

.pl-150 {
  padding-left: 150px !important;
}

.pl-155 {
  padding-left: 155px !important;
}

.pl-160 {
  padding-left: 160px !important;
}

.pl-165 {
  padding-left: 165px !important;
}

.pl-170 {
  padding-left: 170px !important;
}

.pl-175 {
  padding-left: 175px !important;
}

.pl-180 {
  padding-left: 180px !important;
}

.pl-185 {
  padding-left: 185px !important;
}

.pl-190 {
  padding-left: 190px !important;
}

.pl-195 {
  padding-left: 195px !important;
}

.pl-200 {
  padding-left: 200px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-35 {
  padding: 35px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-45 {
  padding: 45px !important;
}

.p-50 {
  padding: 50px !important;
}

.p-55 {
  padding: 55px !important;
}

.p-60 {
  padding: 60px !important;
}

.p-65 {
  padding: 65px !important;
}

.p-70 {
  padding: 70px !important;
}

.p-75 {
  padding: 75px !important;
}

.p-80 {
  padding: 80px !important;
}

.p-85 {
  padding: 85px !important;
}

.p-90 {
  padding: 90px !important;
}

.p-95 {
  padding: 95px !important;
}

.p-100 {
  padding: 100px !important;
}

.p-105 {
  padding: 105px !important;
}

.p-110 {
  padding: 110px !important;
}

.p-115 {
  padding: 115px !important;
}

.p-120 {
  padding: 120px !important;
}

.p-125 {
  padding: 125px !important;
}

.p-130 {
  padding: 130px !important;
}

.p-135 {
  padding: 135px !important;
}

.p-140 {
  padding: 140px !important;
}

.p-145 {
  padding: 145px !important;
}

.p-150 {
  padding: 150px !important;
}

.p-155 {
  padding: 155px !important;
}

.p-160 {
  padding: 160px !important;
}

.p-165 {
  padding: 165px !important;
}

.p-170 {
  padding: 170px !important;
}

.p-175 {
  padding: 175px !important;
}

.p-180 {
  padding: 180px !important;
}

.p-185 {
  padding: 185px !important;
}

.p-190 {
  padding: 190px !important;
}

.p-195 {
  padding: 195px !important;
}

.p-200 {
  padding: 200px !important;
}

.pull-up-0 {
  margin-top: -0px !important;
}

.pull-up-5 {
  margin-top: -5px !important;
}

.pull-up-10 {
  margin-top: -10px !important;
}

.pull-up-15 {
  margin-top: -15px !important;
}

.pull-up-20 {
  margin-top: -20px !important;
}

.pull-up-25 {
  margin-top: -25px !important;
}

.pull-up-30 {
  margin-top: -30px !important;
}

.pull-up-35 {
  margin-top: -35px !important;
}

.pull-up-40 {
  margin-top: -40px !important;
}

.pull-up-45 {
  margin-top: -45px !important;
}

.pull-up-50 {
  margin-top: -50px !important;
}

.pull-up-55 {
  margin-top: -55px !important;
}

.pull-up-60 {
  margin-top: -60px !important;
}

.pull-up-65 {
  margin-top: -65px !important;
}

.pull-up-70 {
  margin-top: -70px !important;
}

.pull-up-75 {
  margin-top: -75px !important;
}

.pull-up-80 {
  margin-top: -80px !important;
}

.pull-up-85 {
  margin-top: -85px !important;
}

.pull-up-90 {
  margin-top: -90px !important;
}

.pull-up-95 {
  margin-top: -95px !important;
}

.pull-up-100 {
  margin-top: -100px !important;
}

.pull-up-105 {
  margin-top: -105px !important;
}

.pull-up-110 {
  margin-top: -110px !important;
}

.pull-up-115 {
  margin-top: -115px !important;
}

.pull-up-120 {
  margin-top: -120px !important;
}

.pull-up-125 {
  margin-top: -125px !important;
}

.pull-up-130 {
  margin-top: -130px !important;
}

.pull-up-135 {
  margin-top: -135px !important;
}

.pull-up-140 {
  margin-top: -140px !important;
}

.pull-up-145 {
  margin-top: -145px !important;
}

.pull-up-150 {
  margin-top: -150px !important;
}

.pull-up-155 {
  margin-top: -155px !important;
}

.pull-up-160 {
  margin-top: -160px !important;
}

.pull-up-165 {
  margin-top: -165px !important;
}

.pull-up-170 {
  margin-top: -170px !important;
}

.pull-up-175 {
  margin-top: -175px !important;
}

.pull-up-180 {
  margin-top: -180px !important;
}

.pull-up-185 {
  margin-top: -185px !important;
}

.pull-up-190 {
  margin-top: -190px !important;
}

.pull-up-195 {
  margin-top: -195px !important;
}

.pull-up-200 {
  margin-top: -200px !important;
}

.push-down-0 {
  margin-bottom: -0px !important;
}

.push-down-5 {
  margin-bottom: -5px !important;
}

.push-down-10 {
  margin-bottom: -10px !important;
}

.push-down-15 {
  margin-bottom: -15px !important;
}

.push-down-20 {
  margin-bottom: -20px !important;
}

.push-down-25 {
  margin-bottom: -25px !important;
}

.push-down-30 {
  margin-bottom: -30px !important;
}

.push-down-35 {
  margin-bottom: -35px !important;
}

.push-down-40 {
  margin-bottom: -40px !important;
}

.push-down-45 {
  margin-bottom: -45px !important;
}

.push-down-50 {
  margin-bottom: -50px !important;
}

.push-down-55 {
  margin-bottom: -55px !important;
}

.push-down-60 {
  margin-bottom: -60px !important;
}

.push-down-65 {
  margin-bottom: -65px !important;
}

.push-down-70 {
  margin-bottom: -70px !important;
}

.push-down-75 {
  margin-bottom: -75px !important;
}

.push-down-80 {
  margin-bottom: -80px !important;
}

.push-down-85 {
  margin-bottom: -85px !important;
}

.push-down-90 {
  margin-bottom: -90px !important;
}

.push-down-95 {
  margin-bottom: -95px !important;
}

.push-down-100 {
  margin-bottom: -100px !important;
}

.push-down-105 {
  margin-bottom: -105px !important;
}

.push-down-110 {
  margin-bottom: -110px !important;
}

.push-down-115 {
  margin-bottom: -115px !important;
}

.push-down-120 {
  margin-bottom: -120px !important;
}

.push-down-125 {
  margin-bottom: -125px !important;
}

.push-down-130 {
  margin-bottom: -130px !important;
}

.push-down-135 {
  margin-bottom: -135px !important;
}

.push-down-140 {
  margin-bottom: -140px !important;
}

.push-down-145 {
  margin-bottom: -145px !important;
}

.push-down-150 {
  margin-bottom: -150px !important;
}

.push-down-155 {
  margin-bottom: -155px !important;
}

.push-down-160 {
  margin-bottom: -160px !important;
}

.push-down-165 {
  margin-bottom: -165px !important;
}

.push-down-170 {
  margin-bottom: -170px !important;
}

.push-down-175 {
  margin-bottom: -175px !important;
}

.push-down-180 {
  margin-bottom: -180px !important;
}

.push-down-185 {
  margin-bottom: -185px !important;
}

.push-down-190 {
  margin-bottom: -190px !important;
}

.push-down-195 {
  margin-bottom: -195px !important;
}

.push-down-200 {
  margin-bottom: -200px !important;
}

.h-100 {
  height: 100px;
}

.h-200 {
  height: 200px;
}

.h-300 {
  height: 300px;
}

.h-400 {
  height: 400px;
}

.h-500 {
  height: 500px;
}

.h-600 {
  height: 600px;
}

.h-700 {
  height: 700px;
}

.h-800 {
  height: 800px;
}

.h-900 {
  height: 900px;
}

.h-sm {
  height: 40vh;
}

.h-md {
  height: 60vh;
}

.h-lg {
  height: 80vh;
}

.fullheight {
  height: 100vh;
}

.min-fullheight {
  min-height: 100vh;
}

.huge {
  height: 150vh;
}

.v-bottom {
  position: absolute !important;
  bottom: 0;
  left: 0;
  right: 0;
  .transition(all 0.3s ease-out);
}

.shadow {
  img {
    .box-shadow(1px 1px 20px 0px rgba(0, 0, 0, 0.2));
  }
}

.max-w--inherit {
  img {
    max-width: inherit;
  }
}

.max-w--35per {
  img {
    max-width: 35%;
  }
}

.text-uppercase{
  text-transform: uppercase;
}
/* Links */
.link-underline {
  position: relative;
}

.link-underline:after {
  position: absolute;
  bottom: -2%;
  left: 0;
  width: 100%;
  height: 2px;
  content: ' ';
  background-color: #1f1f1f;
  .transition(all 0.2s ease-out);
}

.link-underline:hover:after, .link-underline:focus:after {
  .scaleX(1.1);
}

.dark .link-underline:after {
  background-color: #fff;
}

.link-underline.text-primary:after {
  background-color: #2786c9;
}

/* Inline */
.nav-inline li {
  display: inline-block;
}

.nav-inline li a {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  padding: 0 9px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5;
  background: none;
}

.nav-inline li a:hover, .nav-inline li a:focus {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  background: none;
}

.nav-inline.nav-sm a {
  font-size: 11px;
}



/* Carousel
----------------------------------------------------------*/
.carousel {
  position: relative;
}

.carousel .owl-item {
  overflow: hidden;
}

.carousel:hover .owl-nav {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  .scaleX(1);
  visibility: visible;
}

html[dir="rtl"] .carousel {
  direction: ltr;
}

.owl-dots {
  text-align: center;
  .owl-dot {
    display: inline-block;
    margin: 0 8px;
    span {
      display: inline-block;
      width: 11px;
      height: 11px;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      .transition(all 0.2s ease-out);
      background-color: #000000;
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
      opacity: 0.2;
    }
    &.active span {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
      opacity: 0.6;
    }
  }
}

.dark {
  .owl-dots .owl-dot {
    span {
      background-color: rgba(255, 255, 255, 0.4);
    }
    &.active span {
      background-color: rgba(255, 255, 255, 0.75);
    }
  }
}

.owl-theme .owl-nav {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  width: 100%;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  .scaleX(0.9);
  visibility: hidden;
  .transition(all 0.2s ease-out);
  > * {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    .transition(all 0.2s ease-out);
    &:hover, &:focus {
      filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
      opacity: 1;
    }
    &:after, &:before {
      position: absolute;
      top: 0;
      font-size: 26px;
      font-family: themify;
    }
  }
  .owl-prev:before {
    left: -20px;
    content: "\e64a";
  }
  .owl-next:before {
    right: -20px;
    content: "\e649";
  }
}

.inner-controls {
  .owl-dots {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    > .owl-dot span {
      background-color: #fff;
    }
  }
  .owl-nav {
    > * {
      &:after, &:before {
        color: #fff;
      }
    }
    .owl-prev:before {
      left: 20px;
    }
    .owl-next:before {
      right: 20px;
    }
  }
}

/* Price Tables
----------------------------------------------------------*/
.price-table-1 {
  margin-bottom: 20px;
  .head {
    padding: 30px 45px;
    margin-bottom: 15px;
    background: #fff;
    color: #434343;
    h4, .title {
      margin-bottom: 0;
      margin-top: 0;
      display: block;
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      font-size: 24px;
      font-weight: 100;
      line-height: 1.1;
    }
    .price {
      font-size: 52px;
      font-weight: 600;
      line-height: 1;
    }
  }
  .content {
    padding: 0 45px;
    > ul {
      padding: 0;
      list-style: none;
      margin: 0 -45px 15px;
      li {
        padding: 10px 45px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        &:last-child {
          border: none;
        }
        i {
          margin-right: 8px;
          color: #2786c9;
        }
      }
    }
  }
  &.featured {
    .head {
      background: #2786c9;
      color: #fff;
    }
  }
}

.price-table-2 {
  position: relative;
  padding: 25px 25px 80px;
  border: 2px solid #ededed;
  min-height: 490px;
  z-index: 1;
  background: #ffffff;
  h4, .title {
    font-size: 24px;
    font-weight: 100;
    margin-top: 0;
    display: block;
    margin-bottom: 0;
    line-height: 1.1em;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
  }
  .price {
    font-size: 48px;
    line-height: 1.2;
    display: inline-block;
    margin-bottom: 20px;
    font-weight: 300;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    color: #2786c9;
  }
  ul {
    margin-bottom: 45px;
    > li {
      margin-bottom: 10px;
      i {
        margin-right: 8px;
        color: #2786c9;
      }
    }
  }
  .bottom {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    padding-bottom: 25px;
  }
  &.featured {
    color: #fff;
    background-color: #2786c9;
    margin: -5px;
    z-index: 2;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    min-height: 500px;
    border: none;
    .price {
      color: #ffffff;
    }
    .title,
    ul > li i {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.bg-grey {
  .head {
    background: #f6f6f6;
  }
}

/* Buttons
----------------------------------------------------------*/

.btn {
  position: relative;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
  border-radius: 40px;
  padding: 13px 28px 15px;
  border: none;
  color: inherit;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  outline: none;
  border: 2px solid;
  background: transparent;
  margin-bottom: 3px;
}

.btn i {
  font-size: 130%;
  display: inline-block;
  vertical-align: middle;
}

.btn.btn-xs {
  padding: 6px 20px 7px;
}

.btn.btn-sm {
  padding: 10px 24px 11px;
}

.btn.btn-lg {
  padding: 18px 32px 20px;
  font-size: 12px;
}

.btn.btn-default {
  border-color: #ededed;
  color: inherit;
}

.btn.btn-default:hover, .btn.btn-default:focus, .btn.btn-default:active, .btn.btn-default:active:focus, .btn.btn-default:active:hover {
  background-color: #ededed;
  color: inherit;
}

.btn.btn-white {
  border-color: #fff;
}

.btn.btn-primary {
  border-color: #2786c9;
}

.btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary:active:focus, .btn.btn-primary:active:hover {
  border-color: #2786c9;
  background-color: #2786c9;
}

.btn.btn-secondary {
  border-color: #455059;
}

.btn.btn-secondary:hover, .btn.btn-secondary:focus, .btn.btn-secondary:active, .btn.btn-secondary:active:focus, .btn.btn-secondary:active:hover {
  border-color: #455059;
  background-color: #455059;
}

.btn.btn-info {
  border-color: #2786c9;
}

.btn.btn-info:hover, .btn.btn-info:focus, .btn.btn-info:active, .btn.btn-info:active:focus, .btn.btn-info:active:hover {
  border-color: #2786c9;
  background-color: #2786c9;
}

.btn.btn-warning {
  border-color: #f19354;
}

.btn.btn-warning:hover, .btn.btn-warning:focus, .btn.btn-warning:active, .btn.btn-warning:active:focus, .btn.btn-warning:active:hover {
  border-color: #f19354;
  background-color: #f19354;
}

.btn.btn-danger {
  border-color: #e15757;
}

.btn.btn-danger:hover, .btn.btn-danger:focus, .btn.btn-danger:active, .btn.btn-danger:active:focus, .btn.btn-danger:active:hover {
  border-color: #e15757;
  background-color: #e15757;
}

.btn.btn-success {
  border-color: #8ac04b;
}

.btn.btn-success:hover, .btn.btn-success:focus, .btn.btn-success:active, .btn.btn-success:active:focus, .btn.btn-success:active:hover {
  border-color: #8ac04b;
  background-color: #8ac04b;
}

.btn.btn-dark {
  border-color: #1f1f1f;
}

.btn.btn-dark:hover, .btn.btn-dark:focus, .btn.btn-dark:active, .btn.btn-dark:active:focus, .btn.btn-dark:active:hover {
  border-color: #1f1f1f;
  background-color: #1f1f1f;
}

.btn.btn-facebook {
  border-color: #213553;
}

.btn.btn-facebook:hover, .btn.btn-facebook:focus, .btn.btn-facebook:active, .btn.btn-facebook:active:focus, .btn.btn-facebook:active:hover {
  border-color: #213553;
  background-color: #213553;
}

.btn.btn-twitter {
  border-color: #3aa8db;
}

.btn.btn-twitter:hover, .btn.btn-twitter:focus, .btn.btn-twitter:active, .btn.btn-twitter:active:focus, .btn.btn-twitter:active:hover {
  border-color: #3aa8db;
  background-color: #3aa8db;
}

.btn.btn-google-plus {
  border-color: #d04f3e;
}

.btn.btn-google-plus:hover, .btn.btn-google-plus:focus, .btn.btn-google-plus:active, .btn.btn-google-plus:active:focus, .btn.btn-google-plus:active:hover {
  border-color: #d04f3e;
  background-color: #d04f3e;
}

.btn.btn-behance {
  border-color: #1882ff;
}

.btn.btn-behance:hover, .btn.btn-behance:focus, .btn.btn-behance:active, .btn.btn-behance:active:focus, .btn.btn-behance:active:hover {
  border-color: #1882ff;
  background-color: #1882ff;
}

.btn.btn-dribbble {
  border-color: #e95aae;
}

.btn.btn-dribbble:hover, .btn.btn-dribbble:focus, .btn.btn-dribbble:active, .btn.btn-dribbble:active:focus, .btn.btn-dribbble:active:hover {
  border-color: #e95aae;
  background-color: #e95aae;
}

.btn.btn-flickr {
  border-color: #f9429c;
}

.btn.btn-flickr:hover, .btn.btn-flickr:focus, .btn.btn-flickr:active, .btn.btn-flickr:active:focus, .btn.btn-flickr:active:hover {
  border-color: #f9429c;
  background-color: #f9429c;
}

.btn.btn-instagram {
  border-color: #4f86ac;
}

.btn.btn-instagram:hover, .btn.btn-instagram:focus, .btn.btn-instagram:active, .btn.btn-instagram:active:focus, .btn.btn-instagram:active:hover {
  border-color: #4f86ac;
  background-color: #4f86ac;
}

.btn.btn-linkedin {
  border-color: #008bc2;
}

.btn.btn-linkedin:hover, .btn.btn-linkedin:focus, .btn.btn-linkedin:active, .btn.btn-linkedin:active:focus, .btn.btn-linkedin:active:hover {
  border-color: #008bc2;
  background-color: #008bc2;
}

.btn.btn-pinterest {
  border-color: #cb1f24;
}

.btn.btn-pinterest:hover, .btn.btn-pinterest:focus, .btn.btn-pinterest:active, .btn.btn-pinterest:active:focus, .btn.btn-pinterest:active:hover {
  border-color: #cb1f24;
  background-color: #cb1f24;
}

.btn.btn-skype {
  border-color: #00bef4;
}

.btn.btn-skype:hover, .btn.btn-skype:focus, .btn.btn-skype:active, .btn.btn-skype:active:focus, .btn.btn-skype:active:hover {
  border-color: #00bef4;
  background-color: #00bef4;
}

.btn.btn-slack {
  border-color: #44ba97;
}

.btn.btn-slack:hover, .btn.btn-slack:focus, .btn.btn-slack:active, .btn.btn-slack:active:focus, .btn.btn-slack:active:hover {
  border-color: #44ba97;
  background-color: #44ba97;
}

.btn.btn-tumblr {
  border-color: #435971;
}

.btn.btn-tumblr:hover, .btn.btn-tumblr:focus, .btn.btn-tumblr:active, .btn.btn-tumblr:active:focus, .btn.btn-tumblr:active:hover {
  border-color: #435971;
  background-color: #435971;
}

.btn.btn-vimeo {
  border-color: #0bc4ef;
}

.btn.btn-vimeo:hover, .btn.btn-vimeo:focus, .btn.btn-vimeo:active, .btn.btn-vimeo:active:focus, .btn.btn-vimeo:active:hover {
  border-color: #0bc4ef;
  background-color: #0bc4ef;
}

.btn.btn-vine {
  border-color: #00be9b;
}

.btn.btn-vine:hover, .btn.btn-vine:focus, .btn.btn-vine:active, .btn.btn-vine:active:focus, .btn.btn-vine:active:hover {
  border-color: #00be9b;
  background-color: #00be9b;
}

.btn.btn-youtube {
  border-color: #ed4533;
}

.btn.btn-youtube:hover, .btn.btn-youtube:focus, .btn.btn-youtube:active, .btn.btn-youtube:active:focus, .btn.btn-youtube:active:hover {
  border-color: #ed4533;
  background-color: #ed4533;
}

.btn:focus:active, .btn:focus, .btn:active {
  outline: none !important;
}

.btn:active {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-filled {
  color: #fff;
}

.btn-filled:hover, .btn-filled:focus {
  color: #fff;
}

.btn-filled.btn-white {
  background-color: #fff;
  color: #2786c9;
}

.btn-filled.btn-white:hover, .btn-filled.btn-white:focus {
  background-color: #1f6a9e;
  border-color: #1f6a9e;
  color: #fff;
}

.btn-filled.btn-default {
  background-color: #ededed;
  color: #434343;
}

.btn-filled.btn-default:hover, .btn-filled.btn-default:focus, .btn-filled.btn-default:active, .btn-filled.btn-default:active:focus, .btn-filled.btn-default:active:hover {
  color: #1f1f1f;
  background-color: lightgray;
  border-color: lightgray;
}

.btn-filled.btn-primary {
  background-color: #2786c9;
}

.btn-filled.btn-primary:hover, .btn-filled.btn-primary:focus, .btn-filled.btn-primary:active, .btn-filled.btn-primary:active:focus, .btn-filled.btn-primary:active:hover {
  background-color: #1f6a9e;
  border-color: #1f6a9e;
}

.btn-filled.btn-secondary {
  background-color: #455059;
}

.btn-filled.btn-secondary:hover, .btn-filled.btn-secondary:focus, .btn-filled.btn-secondary:active, .btn-filled.btn-secondary:active:focus, .btn-filled.btn-secondary:active:hover {
  background-color: #2f363c;
  border-color: #2f363c;
}

.btn-filled.btn-info {
  background-color: #2786c9;
}

.btn-filled.btn-info:hover, .btn-filled.btn-info:focus, .btn-filled.btn-info:active, .btn-filled.btn-info:active:focus, .btn-filled.btn-info:active:hover {
  background-color: #1f6a9e;
  border-color: #1f6a9e;
}

.btn-filled.btn-warning {
  background-color: #f19354;
}

.btn-filled.btn-warning:hover, .btn-filled.btn-warning:focus, .btn-filled.btn-warning:active, .btn-filled.btn-warning:active:focus, .btn-filled.btn-warning:active:hover {
  background-color: #ed7525;
  border-color: #ed7525;
}

.btn-filled.btn-danger {
  background-color: #e15757;
}

.btn-filled.btn-danger:hover, .btn-filled.btn-danger:focus, .btn-filled.btn-danger:active, .btn-filled.btn-danger:active:focus, .btn-filled.btn-danger:active:hover {
  background-color: #d92c2c;
  border-color: #d92c2c;
}

.btn-filled.btn-success {
  background-color: #8ac04b;
}

.btn-filled.btn-success:hover, .btn-filled.btn-success:focus, .btn-filled.btn-success:active, .btn-filled.btn-success:active:focus, .btn-filled.btn-success:active:hover {
  background-color: #70a038;
  border-color: #70a038;
}

.btn-filled.btn-dark {
  background-color: #1f1f1f;
}

.btn-filled.btn-dark:hover, .btn-filled.btn-dark:focus, .btn-filled.btn-dark:active, .btn-filled.btn-dark:active:focus, .btn-filled.btn-dark:active:hover {
  background-color: #050505;
  border-color: #050505;
}
.dark .btn.btn-default { border-color: #fff; }
.dark .btn.btn-default:hover, .dark .btn.btn-default:focus { color: #1f1f1f; background-color: #fff; }

.i-before {
  margin-right: 8px;
}

.i-after {
  margin-left: 8px;
}

.i-before-after {
  margin-right: 8px;
  margin-left: 8px;
}

/* Recent Posts
----------------------------------------------------------*/
.list-posts {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-posts > li {
  margin-bottom: 15px;
}

.list-posts > li > a {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 13px;
  display: block;
}

.list-posts > li > a:after {
  position: relative;
  font-family: 'themify';
  width: 15px;
  height: 15px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  content: "\e628";
  margin-left: 5px;
  padding: 2px;
  text-align: center;
  vertical-align: middle;
  font-size: 8px;
  display: inline-block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.list-posts > li > .date {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  font-size: 12px;
}

html[dir="rtl"] .list-posts > li > a:after {
  margin-left: 0;
  margin-right: 5px;
  content: "\e629";
}

.dark .list-posts > li > a:after {
  background: rgba(255, 255, 255, 0.2);
}

.dark .list-posts > li > a:after {
  background: rgba(255, 255, 255, 0.15);
}

/* Twitter
----------------------------------------------------------*/
.twitter-feed ul {
  list-style: none;
  margin: 0 0 0 0;
  padding: 0;
}

.twitter-feed ul li {
  position: relative;
  margin-bottom: 20px;
  display: flex
}

.twitter-feed ul li a {
  font-weight: 600;
  color: inherit;
}

.twitter-feed ul li .tztwd-tweet-data {
  display: block;
  font-size: 95%;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5;
  margin-top: 4px;
  a {
    font-weight: 100;
  }
}

.twitter-feed {
  .avata-info {
    font-size: 120%;
    color: #3aa8db;
  }
  .tz-content_tweet {
    padding-top: 4px;
    padding-left: 10px;
  }
  .tztwd-tweet-data {
    display: block;
  }
}

/* Icons
----------------------------------------------------------*/
.tz-icon {
  position: relative;
  display: inline-block;
  font-size: 52px;
  width: 72px;
  vertical-align: middle;
  margin-bottom: 3px;
}

.tz-icon.tz-icon-xs {
  font-size: 16px;
  width: 36px;
}

.tz-icon.tz-icon-sm {
  font-size: 28px;
  width: 48px;
}

.tz-icon.tz-icon-lg {
  font-size: 82px;
  width: 112px;
}

.tz-icon.tz-icon-xl {
  font-size: 144px;
  width: 164px;
}

.tz-icon.tz-icon-xxl {
  font-size: 208px;
  width: 240px;
}

.tz-icon.tz-icon-default {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=15);
  opacity: 0.15;
}

.tz-icon.tz-icon-grey {
  color: #d2d2d2;
}

.tz-icon.tz-icon-primary {
  color: #2786c9;
}

.tz-icon.tz-icon-secondary {
  color: #455059;
}

.tz-icon.tz-icon-info {
  color: #2786c9;
}

.tz-icon.tz-icon-warning {
  color: #f19354;
}

.tz-icon.tz-icon-danger {
  color: #e15757;
}

.tz-icon.tz-icon-success {
  color: #8ac04b;
}

.tz-icon.tz-icon-dark {
  color: #1f1f1f;
}

.tz-icon.tz-icon-facebook {
  color: #213553;
}

.tz-icon.tz-icon-twitter {
  color: #3aa8db;
}

.tz-icon.tz-icon-google-plus {
  color: #d04f3e;
}

.tz-icon.tz-icon-behance {
  color: #1882ff;
}

.tz-icon.tz-icon-dribbble {
  color: #e95aae;
}

.tz-icon.tz-icon-flickr {
  color: #f9429c;
}

.tz-icon.tz-icon-instagram {
  color: #4f86ac;
}

.tz-icon.tz-icon-linkedin {
  color: #008bc2;
}

.tz-icon.tz-icon-pinterest {
  color: #cb1f24;
}

.tz-icon.tz-icon-skype {
  color: #00bef4;
}

.tz-icon.tz-icon-slack {
  color: #44ba97;
}

.tz-icon.tz-icon-tumblr {
  color: #435971;
}

.tz-icon.tz-icon-vimeo {
  color: #0bc4ef;
}

.tz-icon.tz-icon-vine {
  color: #00be9b;
}

.tz-icon.tz-icon-youtube {
  color: #ed4533;
}

.tz-icon.tz-icon-square, .tz-icon.tz-icon-circle {
  height: 72px;
  font-size: 32px;
  text-align: center;
  color: #fff;
}

.tz-icon.tz-icon-square > *, .tz-icon.tz-icon-circle > * {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.tz-icon.tz-icon-square.tz-icon-xs, .tz-icon.tz-icon-circle.tz-icon-xs {
  height: 36px;
  font-size: 13px;
}

.tz-icon.tz-icon-square.tz-icon-sm, .tz-icon.tz-icon-circle.tz-icon-sm {
  height: 48px;
  font-size: 22px;
}

.tz-icon.tz-icon-square.tz-icon-lg, .tz-icon.tz-icon-circle.tz-icon-lg {
  height: 112px;
  font-size: 48px;
}

.tz-icon.tz-icon-square.tz-icon-xl, .tz-icon.tz-icon-circle.tz-icon-xl {
  height: 164px;
  font-size: 78px;
}

.tz-icon.tz-icon-square.tz-icon-white, .tz-icon.tz-icon-circle.tz-icon-white {
  background-color: #fff;
  color: #2786c9;
}

.tz-icon.tz-icon-square.tz-icon-grey, .tz-icon.tz-icon-circle.tz-icon-grey {
  background-color: #ededed;
}

.tz-icon.tz-icon-square.tz-icon-primary, .tz-icon.tz-icon-circle.tz-icon-primary {
  background-color: #2786c9;
}

.tz-icon.tz-icon-square.tz-icon-secondary, .tz-icon.tz-icon-circle.tz-icon-secondary {
  background-color: #455059;
}

.tz-icon.tz-icon-square.tz-icon-info, .tz-icon.tz-icon-circle.tz-icon-info {
  background-color: #2786c9;
}

.tz-icon.tz-icon-square.tz-icon-warning, .tz-icon.tz-icon-circle.tz-icon-warning {
  background-color: #f19354;
}

.tz-icon.tz-icon-square.tz-icon-danger, .tz-icon.tz-icon-circle.tz-icon-danger {
  background-color: #e15757;
}

.tz-icon.tz-icon-square.tz-icon-success, .tz-icon.tz-icon-circle.tz-icon-success {
  background-color: #8ac04b;
}

.tz-icon.tz-icon-square.tz-icon-dark, .tz-icon.tz-icon-circle.tz-icon-dark {
  background-color: #1f1f1f;
}

.tz-icon.tz-icon-square.tz-icon-facebook, .tz-icon.tz-icon-circle.tz-icon-facebook {
  background-color: #213553;
}

.tz-icon.tz-icon-square.tz-icon-twitter, .tz-icon.tz-icon-circle.tz-icon-twitter {
  background-color: #3aa8db;
}

.tz-icon.tz-icon-square.tz-icon-google-plus, .tz-icon.tz-icon-circle.tz-icon-google-plus {
  background-color: #d04f3e;
}

.tz-icon.tz-icon-square.tz-icon-behance, .tz-icon.tz-icon-circle.tz-icon-behance {
  background-color: #1882ff;
}

.tz-icon.tz-icon-square.tz-icon-dribbble, .tz-icon.tz-icon-circle.tz-icon-dribbble {
  background-color: #e95aae;
}

.tz-icon.tz-icon-square.tz-icon-flickr, .tz-icon.tz-icon-circle.tz-icon-flickr {
  background-color: #f9429c;
}

.tz-icon.tz-icon-square.tz-icon-instagram, .tz-icon.tz-icon-circle.tz-icon-instagram {
  background-color: #4f86ac;
}

.tz-icon.tz-icon-square.tz-icon-linkedin, .tz-icon.tz-icon-circle.tz-icon-linkedin {
  background-color: #008bc2;
}

.tz-icon.tz-icon-square.tz-icon-pinterest, .tz-icon.tz-icon-circle.tz-icon-pinterest {
  background-color: #cb1f24;
}

.tz-icon.tz-icon-square.tz-icon-skype, .tz-icon.tz-icon-circle.tz-icon-skype {
  background-color: #00bef4;
}

.tz-icon.tz-icon-square.tz-icon-slack, .tz-icon.tz-icon-circle.tz-icon-slack {
  background-color: #44ba97;
}

.tz-icon.tz-icon-square.tz-icon-tumblr, .tz-icon.tz-icon-circle.tz-icon-tumblr {
  background-color: #435971;
}

.tz-icon.tz-icon-square.tz-icon-vimeo, .tz-icon.tz-icon-circle.tz-icon-vimeo {
  background-color: #0bc4ef;
}

.tz-icon.tz-icon-square.tz-icon-vine, .tz-icon.tz-icon-circle.tz-icon-vine {
  background-color: #00be9b;
}

.tz-icon.tz-icon-square.tz-icon-youtube, .tz-icon.tz-icon-circle.tz-icon-youtube {
  background-color: #ed4533;
}

.tz-icon.tz-icon-square {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.tz-icon.tz-icon-circle {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.i-before {
  margin-right: 8px;
}

.i-after {
  margin-left: 8px;
}

.i-before-after {
  margin-right: 8px;
  margin-left: 8px;
}

html[dir="rtl"] .i-before {
  margin-left: 8px;
  margin-right: 0;
}

html[dir="rtl"] .i-after {
  margin-right: 8px;
  margin-left: 0;
}

/* Fonts */
.font-secondary {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

/* Headings - Default*/
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  display: block;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

h1.font-secondary, .h1.font-secondary, h2.font-secondary, .h2.font-secondary, h3.font-secondary, .h3.font-secondary, h4.font-secondary, .h4.font-secondary, h5.font-secondary, .h5.font-secondary, h6.font-secondary, .h6.font-secondary {
  font-weight: 400;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-weight: 100;
}

h1, .h1 {
  font-size: 48px;
  margin-bottom: 22px;
}

@media only screen and (max-width: 767px) {
  h1, .h1 {
    font-size: 38px;
  }
}

h1.text-lg, .h1.text-lg {
  font-size: 58px;
}

@media only screen and (max-width: 991px) {
  h1.text-lg, .h1.text-lg {
    font-size: 48px;
  }
}

@media only screen and (max-width: 767px) {
  h1.text-lg, .h1.text-lg {
    font-size: 44px;
  }
}

h1.text-xl, .h1.text-xl {
  font-size: 96px;
}

@media only screen and (max-width: 991px) {
  h1.text-xl, .h1.text-xl {
    font-size: 72px;
  }
}

@media only screen and (max-width: 767px) {
  h1.text-xl, .h1.text-xl {
    font-size: 48px;
  }
}

h2, .h2 {
  font-size: 36px;
  margin-bottom: 28px;
}

@media only screen and (max-width: 767px) {
  h2, .h2 {
    font-size: 34px;
  }
}

h3, .h3 {
  font-size: 30px;
  margin-bottom: 24px;
}

h4, .h4 {
  font-size: 24px;
  margin-bottom: 18px;
}

h5, .h5 {
  font-size: 18px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  margin-bottom: 14px;
}

h6, h6 {
  font-size: 13px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  margin-bottom: 12px;
}

@media only screen and (max-width: 991px) {
  h1 {
    font-size: 38px;
  }

  h1.text-lg {
    font-size: 48px;
  }

  h1.text-xl {
    font-size: 72px;
  }

  h2 {
    font-size: 34px;
  }
}

@media only screen and (max-width: 991px) {
  h1.text-xl {
    font-size: 60px;
  }
}

/* Heading - Metal*/
.typo__style--2 {
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 0;
    display: block;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }

  h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
    font-weight: 200;
  }
}

/* Progress Bars
----------------------------------------------------------*/
.progress {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  height: 32px;
  background-color: #ededed;
  margin-bottom: 10px;
  .sppb-progress {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    line-height: 24px;
    margin: 0;
  }
  &.progress-bar-rounded {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    .progress-bar,
    .sppb-progress {
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
    }
  }
}

.progress .progress-bar {
  line-height: 32px;
  background-color: #2786c9;
  text-align: right;
  padding: 0 10px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress.progress-2 {
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.progress.progress-2 .progress-bar {
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  padding: 0 15px;
}
