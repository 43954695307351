@media (max-width: 1440px){

}
@media (max-width: 1366px){
  .tz-about-app,.tz-about-app2{
    padding: 0 0 0 50px;
  }

}
@media (max-width: 1200px){
  .tz-image-sections .sppb-addon-single-image-container .sppb-img-responsive {
    margin-left: 0;

  }
  h1.text-xl, .h1.text-xl {
    font-size: 64px;
  }
  .tz_what_new_responsive{
    .sppb-column.nav-black{
      padding: 100px 0 0 0 !important;
    }
  }
  .tz-about-pdl70{
    padding-left: 15px;
  }
  .tz-content-center > .sppb-column-addons{}
}
@media (max-width: 1024px){
  .tz_custom_pd_lr_210,.tz_custom_pd_rl300,.tz_custom_pd_rl200{
    padding: 0;
  }
  .tz_custom_mr_rl300,.tz_custom_mr_rl150{
    margin: 0;
  }
  .jsn-master .control-group div.controls {
    display: inline-block;
    width: 100%;

  }
  .jsn-master .control-group div.controls input{
    padding: 13px 0;
  }
  .jsn-master .jsn-narrow .jsn-input-mini-fluid, .jsn-master .jsn-narrow .jsn-input-small-fluid, .jsn-master .jsn-narrow .jsn-input-medium-fluid, .jsn-master .jsn-narrow .jsn-input-xlarge-fluid, .jsn-master .jsn-narrow .jsn-input-fluid, .jsn-master .jsn-narrow .jsn-columns-container.jsn-columns-count-two .jsn-column-item, .jsn-master .jsn-narrow .jsn-columns-container.jsn-columns-count-three .jsn-column-item, .jsn-master .jsn-narrow .jsn-columns-container.jsn-columns-count-no .jsn-column-item, .jsn-master .jsn-bootstrap .jsn-narrow .input-small, .jsn-master .jsn-bootstrap .jsn-narrow .input-medium, .jsn-master .jsn-bootstrap .jsn-narrow .input-file, .jsn-master .jsn-bootstrap .jsn-narrow .jsn-column-item.jsn-uniform-others textarea.jsn-value-Others {
    width: 100% !important;
  }
  #jsn_form_1.jsn-master .jsn-bootstrap .jsn-form-content .control-group .controls input, #jsn_form_1.jsn-master .jsn-bootstrap .jsn-form-content .control-group .controls select, #jsn_form_1.jsn-master .jsn-bootstrap .jsn-form-content .control-group .controls textarea {
    background: #455059;
    color: #6A737A;
    border: 1px solid #6A737A;
    width: auto !important;
    display: inline-block;
    min-width: 100%;
    text-indent: 15px;
  }

  .tz_form_content{
    margin: 0;

  }
  .tz-about-app,.tz-about-app2{
    padding: 0 0 0 15px;
  }
  .sppb-addon-image-content{
    .sppb-image-holder{
      height: 50vh;
      width: 100%;
      position: relative;
    }
  }
  .tz-image-sections .sppb-addon-single-image-container .sppb-img-responsive {
    max-width: 100%;
  }
  .tz_what_new_responsive .sppb-column.nav-black {
    padding: 100px 0 40px 0 !important;
  }
  .tz-content-center{
    height: auto;
  }
  .tz-content-center > .sppb-column-addons{
    top: 0;
    position: static;
    transform: translate3d(0px, 0px, 0px);
  }
  .tz-sevicer-pdr100{
    padding-right: 15px;
  }
  body .tz_contact .tz_style_2 .tz-content {
    padding: 30px 15px;
  }
}
@media (max-width: 991px) {
  .section-image-edge .container div[class*="col-"]{
    position: relative;
    top: 0;
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .tzbg-image{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
  }
  div.plazart-megamenu .nav > .dropdown .dropdown-toggle .caret{
    width: 20px;
    height: 20px;
  }
  .owl-theme div.owl-dots{
    margin-top: 0;
  }
  .sep-line.dark{
    display: none;
  }
}
@media (max-width: 768px){
  .page-title h1{
    font-size: 24px;
  }
  .navbar{
    position: relative;
  }
  .tz__header{
    padding: 0;
  }
  .tz-Modal {
    height: 500px;
  }
  #tz-logo, .tz-logo-image1, .tz-logo-image2 {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .nav>li>a{
    padding: 0 6px;
  }
  .plazart-megamenu .nav > .dropdown .dropdown-toggle:after{
    margin-left: 2px;
  }
  .tz-image-sections .sppb-row{
    display: block;
  }
  .responsive_button{
    .w-50{
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .question-item{
    margin-bottom: 20px;
  }

  .tz-main-menu-wrapper{
    > div{
      padding: 50px 0 20px;
      top: 0;
      transform: initial;
      width: 100%;
    }
    &.right{
      right: 50px;
    }
    a.tz-close{
      right: 55px;
    }
  }
  .tz-logo-image1{
    height: 0;
  }
  .tz-homeType2.crt-top{
    #tz-main-menu{
      background: #ffffff;
    }
  }
  h1, .h1 {
    font-size: 32px;
    margin-bottom: 22px;
  }
  .footer_agency{
    .v-center-items{
      display: block !important;
    }
  }
  .res-center-align{
    text-align: center !important;
    &.v-center-items{
      display: block !important;
    }
  }
  .dropdown-submenu > a:after {
    font-family: themify;
    font-size: 80%;
    content: "\e62a" !important;
    margin-left: 9px;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    border: 0 !important;
    float: right;
    width: 4px !important;
    height: 4px !important;
    text-align: center;
  }
}
@media (max-width: 767px){
  .right-menu{
    position: absolute;
    right: 40px;
  }
  .full-menu{
    padding: 0;
  }
  div.plazart-megamenu .nav > .dropdown .dropdown-toggle .caret{
    width: 35px;
    height: 35px;
  }
  .res-m-0{
    margin: 0 !important;
  }
  .res-p-0{
    padding: 0 !important;
  }
  .res-p-15{
    padding: 15px !important;
  }
  .hikashop_products_listing .hikashop_listing_img_title .onsale{
    left: 0 !important;
  }
  .plazart-megamenu .navbar-nav.level0 {
    overflow: auto;
    height: auto;
    max-height: 80vh;
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    margin-top: 0;
  }
  ul.level0 li.mega-align-justify .dropdown-menu > .mega-dropdown-inner{
    padding-left: 15px;
  }
  div.post{
    .post-content {
      display: block;
      .post-content_left {
        width: auto;
        padding-right: 30px;
        text-align: left;
        margin-bottom: 15px;
      }
    }
  }
  .tz_contact .tz_style_3 #contact-form{
    width: 100%;
  }

  body div.itemComments .itemCommentsForm,body div.itemComments .tzListComment {
    display: block;
  }
  .tz-addon-countdown{
    .sppb-countdown-number,.tz-countdown-number{
      font-size: 35px !important;
    }
  }
  .tz-addon-countdown .countdown-text-wrap .sppb-addon-title {
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .res-pull-center{
    float: none !important;
  }
  .res-align-center{
    text-align: center;
  }
  body div.itemComments .block-left{
    min-width: 100%;
    max-width: 100%;
    text-align: left;
  }
  div.itemBackToTop{
    text-align: center;
    padding-top: 15px;
  }
  div.itemView .tzItemBody, div.tagItemList .tzItemBody, div.userItemList .tzItemBody, div.itemList .tzItemBody{
    display: block;
    .block-left{
      min-width: 100%;
      padding: 0;
      text-align: left;
    }
    .block-left > *{
      display: inline-block;
      margin-right: 15px;
    }
  }
  .page-title h1{
    font-size: 18px;
  }

  #breadcrumb{
    .page-title h1 {
      text-align: center;
    }
    .breadcrumb{
      text-align: center;
    }
  }
  .tz_contact .tz_style_2{
    display: block !important;
  }
  ul.nav-stacked{
    display: inline-block;
    >li{
      float: left;
    }
  }
  .widget-follow{
    display: inline-block;
  }
  .hidden-seach{
    .search_icon_res{
      display: none;
    }
  }

  .res-pull-center.pull-right{
    float: none !important;
  }
  .tz_breadcrumb .tz_left, .tz_breadcrumb .tz_right{
    width: 100% !important;
  }
  .tz_flex.tz_breadcrumb{
    display: block;
  }
  .tz_breadcrumb .tz_right {

    text-align: left !important;
    ul.breadcrumb{
      display: inline-block;
    }
  }
  .text-lg h2.sppb-person-designation{
    font-size: 32px;
  }
  .tz_fix_menu_on_slider.tz-menu-homeapp{
    moz-transition: all 0.1s ease-out;
    -o-transition: all 0.1s ease-out;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
  }
  #tz-logo a{
    position: absolute;
    text-align:  center;
    left: 15px;
  }
  .overflow-hidden{
    overflow: hidden;
  }


  .navbar-nav {
    float: none;
    text-align: left;
  }
  .plazart-megamenu .row .mega-col-nav.res-menu-col3 {
    width: 33.33333333%!important;
    min-width: 0 !important;
    float: left;
  }
  .plazart-megamenu .row .mega-col-nav.res-menu-col4 {
    width: 25%!important;
    min-width: 0 !important;
    float: left;
  }
  .plazart-mainnav{
    .nav > .dropdown .dropdown-toggle:after{
      float: right;
    }
    .btn{
      border: 0;
      box-shadow: none;
    }
  }
  .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {

    outline: 0 auto -webkit-focus-ring-color;
  }
  .nav > li > a {
    padding: 0 19px;
    border-bottom: 1px solid rgba(0,0,0,0.15);
    line-height: 40px;
  }

  .text-right {
    position: relative;
    z-index: 10;
  }
  .navbar-toggle{
    margin-right: 0;
    &.btn{
      margin-bottom: 15px;
      padding: 13px 20px 15px;
    }
  }
  .tz_search_menu{
    z-index: 15;
  }
  .search_icon_res{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    padding: 20px;
    font-size: 20px;
  }
  .search_icon{
    display: none !important;
  }
  .tz-logo-image1{
    height: auto;
    position: absolute;
  }
  #tz-main-menu{
    top: 0;
    right: 0;
  }

  .tz-main-menu-wrapper{
    > div{
      padding: 50px 15px 20px;

    }
  }
  .tz__header{
    &.fixed{
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -o-transform: translate(0, 0);
      transform: translate(0, 0);
      visibility: visible;
      opacity: 1;
    }
  }

  .tz__header--transparent.out .plazart-megamenu ul li a {
    color: #434343;
  }
  #tz-tz-logo a {
    position: absolute;
    text-align: center;
    left: 15px;
  }
  .tz__header--transparent:not(.out) .plazart-megamenu ul li a {
    color: #434343;
  }
  .tz-main-menu-wrapper.right {
    right: 0;
  }
  .tz-main-menu-wrapper .tz-menu-panel{
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);

  }
  .tz-logo-agency2{
    position: absolute;
    top:0;
    left: 0;
  }
  .tz-homeType2.crt-top #tz-main-menu {
     background: transparent;
  }
  div.plazart-megamenu .dropdown-menu .mega-nav {
    box-shadow: none;
  }
  div.plazart-megamenu div.mega-nav > li a, div.plazart-megamenu div.dropdown-menu .mega-nav > li a {
    padding-right: 7px;
  }
  div.plazart-megamenu div.dropdown-menu .row .mega-nav > li {
    border-bottom: 0;
  }
  div.plazart-megamenu .mega-align-justify .dropdown-menu .mega-nav.level1, div.plazart-megamenu .mega-align-left .dropdown-menu .mega-nav.level1, div.plazart-megamenu .mega-align-right .dropdown-menu .mega-nav.level1, div.plazart-megamenu .mega-align-center .dropdown-menu .mega-nav.level1 {
    padding: 10px 0 !important;
  }
  div.plazart-megamenu .dropdown-menu .mega-nav li a:hover {
   -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    color: #2786c9;
  }
  .padding-lr-0{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media (max-width: 480px){
  #sbox-window{
    width:  auto !important;
    height: auto !important;
    top: 25% !important;
    left: 0 !important
  }
  div.plazart-megamenu div.mega-nav > li a, div.plazart-megamenu div.dropdown-menu .mega-nav > li a {
    font-size: 11px;
    font-weight: 400;
    padding: 15px 11px;
  }
  .tz-main-menu-wrapper{
    width: 100%;
  }
  .plazart-megamenu{
    .navbar-nav.level0{
      overflow: auto;
      height: auto;
      max-height: 80vh;
    }
    .row{
      .mega-col-nav.res-menu-col3 {
        width: 100%!important;
        min-width: 0 !important;
        float: left;
      }
      .mega-col-nav.res-menu-col4 {
        width: 100%!important;
        min-width: 0 !important;
        float: left;
      }
    }
  }
  .subscribe {
    .mc-field-group,.clear{
      width: 100%;
    }

  }
  .btn-block {
    width: 100%;
  }
  .sppb-addon-image-content.tz-service .sppb-content-holder{
    padding: 70px 0 70px 0 !important;
  }
  //.plazart-megamenu .row, .plazart-megamenu .mega-dropdown-menu, .plazart-megamenu .row [class*="col-lg-"], .plazart-megamenu .row [class*="col-md-"], .plazart-megamenu .row [class*="col-sm-"], .plazart-megamenu .row [class*="col-xs-"]{
  //  padding: 0;
  //}
}