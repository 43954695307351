a {
  color: inherit;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

.full-width {
  width: 100%;
}

img {
  vertical-align: middle;
}

h3, .h3 {
  font-size: 30px;
  margin-bottom: 24px;
}

h4, .h4 {
  font-size: 24px;
  margin-bottom: 18px;
}

h6, h6 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
  font-size: 13px;
  margin-bottom: 12px;
}

.dark {
  color: #ffffff;
  .text-muted {
    color: rgba(255, 255, 255, 0.5);
  }
  .sep-line {
    background: rgba(255, 255, 255, .2);
  }
  .k2-layout-recent .moduleItemTitle {
    color: #ffffff;
  }
  .k2-layout-recent .tz-label {
    color: #ffffff;
  }
}

.pb120 {
  padding-bottom: 120px;
}

ul {
  padding: 0 !important;
}

.fload-r {
  float: right;
}

.text-align-right {
  text-align: right;
}

.p-static {
  position: static;
}

.lead {
  .sppb-addon-content {
    font-size: 16px;
    margin-bottom: 25px;
  }
}

.fullheight {
  height: 100vh;
}

.text-uppercase1 {
  .sppb-section-title {
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }
}

.mb-60 {
  margin-bottom: 60px !important;
}

.0 {
  margin-bottom: 50px;
}

.overflow-hidden {
  overflow: hidden;
}

.letter-spacing {
  letter-spacing: 0.2px;
}

.v-center-items {
  align-items: center;
  display: flex !important;
  flex-direction: row;
}

.tz-about-pdl70 {
  padding-left: 70px;
}

.tz-about-pdr70 {
  padding-right: 70px;
}

.tz-sevicer-pdr100 {
  padding-right: 100px;
}

.White {
  color: #ffffff;
  .list-lined {
    li {
      border-color: rgba(255, 255, 255, 0.15);
    }
  }
}

.top__-70 {
  top: -70px;
  position: relative;
}

.top__-30 {
  top: -30px;
  position: relative;
}

body {
  color: #434343;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
}

li {
  list-style-type: none;
}

.mt-10 {
  margin-top: 10px !important;
}

.fz-12 {
  font-size: 12px;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}


.pt-70 {
  padding-top: 70px;
}

//center
//.section-double, .tzjs-image {
//  height: 50vw;
//  max-height: 700px;
//  padding: 0;
//  position: relative;
//  .sppb-addon-single-image-container {
//    position: static;
//  }
//
//}

.section-double {
  .tz__block {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
  }
  .content {
    padding: 0 7%;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .image {
    padding: 0;
  }
}

.vh-50 {
  .sppb-addon-content {
    height: 50vh;
  }
}

.tz-inline {
  display: inline-block;
}

.tz-block {
  display: block;
  width: 100%;
}

.text-alight-right {
  text-align: right;

}

.text-alight-left {
  &.v-center {
    text-align: left;
  }
}

.text-alight-right {
  text-align: center;
}

a:focus, a:hover {
  color: #2786c9;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.icon.icon-xl {
  font-size: 144px;
}

.text-muted-2x {
  color: rgba(0, 0, 0, 0.2);
}

//load page
@-moz-keyframes loaderRotate {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@-webkit-keyframes loaderRotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes loaderRotate {
  0% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@-moz-keyframes loaderTurn {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -moz-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -moz-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@-webkit-keyframes loaderTurn {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes loaderTurn {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -moz-transform: rotate(450deg);
    -ms-transform: rotate(450deg);
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

#page-loader {
  background: #fff none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
  .loader-1 {
    margin-left: -32px;
    margin-top: -32px;
    .loader-1.loader-primary {
      stroke: #2786c9;
    }
  }
}

svg:not(:root) {
  overflow: hidden;
}


.loader-1 {
  animation: 1.4s linear 0s normal none infinite running loaderRotate;
  stroke: #d2d2d2;
  .circle {
    animation: 1.4s ease-in-out 0s normal none infinite running loaderTurn;
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center 50% 0;
  }
}

#page-loader > * {
  left: 50%;
  position: absolute;
  top: 50%;
}

.tz_container-fluid {
  .container-fluid {
    padding: 0;
  }

}

.text-muted-w4 {
  color: rgba(255, 255, 255, 0.4);
}

.tzpp_bootstrap3 .padding-lr-0,
div.padding-lr-0,
.padding-lr-0 {
  padding-left: 0;
  padding-right: 0;
  //overflow: hidden;
}

.margin-lr--15 {
  margin-right: -15px;
  margin-left: -15px;

}

.margin-b-20 {
  margin-bottom: 20px;
}

.text-spacing {
  letter-spacing: 0.2em;
}
.sppb-btn{
  padding: 13px 28px 15px ;
}
.margin-lr--15a {
  margin-right: -15px;
  margin-left: -15px;
  .sppb-container-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.sppb-panel-heading {
  padding: 18px 0 18px 45px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  &.active {
    .sppb-toggle-direction.tz_toggle {
      -webkit-transform: translateY(-50%) rotate(180deg);
      transform: translateY(-50%) rotate(180deg);
      i {
        &:before {
          content: "\f056";
        }

      }
    }
  }
  .sppb-toggle-direction.tz_toggle {
    right: auto;
    left: 6px;
    i {
      color: #d2d2d2;
      font-size: 27px;
    }
  }
}

.sppb-panel-body {
  border-top-color: transparent;
  border-top: 0;
  padding-left: 45px;
}

.sppb-panel-group {
  > .sppb-panel:first-child {
    border-top-width: 0;
  }
}

.sppb-panel-default > .sppb-panel-heading {
  background: #ffffff;
}

.tz-panel, .sppb-panel {
  border: 0;
}

.tz-panel {
  .panel-title {
    a {
      font-size: 20px;
      font-weight: 300;
    }
  }
}

.tz-FeatureBox {

  .sppb-addon-title {
    font-size: 17px;

  }
  .sppb-addon-text {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 20px;
  }
}

p.lead {
  &.black {
    margin-top: 28px;
    color: #434343;
  }
  font-size: 16px;
  margin-bottom: 26px;
}

.tz-addon-countdown {
  text-align: center;
  .sppb-countdown-number, .sppb-countdown-text {
    display: inline-block;
  }
  .countdown-text-wrap {
    .sppb-addon-title {
      font-size: 30px;
      font-weight: 300;
      margin-bottom: 45px;
      margin-top: 23px;
    }
  }

  .sppb-countdown-number, .sppb-countdown-text {
    margin: 0;
    line-height: 24px;
  }
  .tz-inline {
    position: relative;
    margin: 20px 3px 0 3px;
    padding-bottom: 25px;
  }
  .tz-countdown-number {
    font-size: 74px !important;
  }
  .sppb-countdown-number {
    font-size: 74px !important;
    font-weight: 300;
    display: inline-block;
    clear: both;
  }
  .sppb-countdown-days {
    margin-right: 25px;
  }
  .sppb-countdown-text {
    display: inline-block;
  }
  .countdown-days-text {
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #222222;
  }

}

.tz_form_content {
  padding: 0 12%;
  position: relative;
  margin: 50px 0;
  .sppb-addon-content {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 300;
  }
  h2 {
    margin-bottom: 26px;
  }
  .dark {
    .sppb-addon-content {
      margin-bottom: 38px;
    }
  }
}

.jsn-uniform.jsn-master .jsn-bootstrap .jsn-form-content .control-groupx {
  padding: 0;
}

.select2-search {
  padding-top: 5px;
}

.jsn-master {
  div.chzn-container {
    display: none;
  }

  .form-actions {
    padding: 0 !important;
  }
  .control-group {
    padding: 0 !important;

    .control-label {
      display: none;
    }

    div.controls {
      overflow: visible;
      .select2-choice {
        height: auto;
        background: #455059;
        color: #6A737A;
        border: 1px solid #6A737A;
        border-radius: 0;
        padding: 13px 10px;
        line-height: 1.5;
        position: relative;
        box-shadow: none;
        &:after {
          position: absolute;
          top: 50%;
          display: block;
          -moz-transform: translate3d(0, -50%, 0);
          -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
          right: 13px;
          font-family: themify;
          content: "\e62a";
          color: rgba(255, 255, 255, 0.5);
        }
        div {
          background: transparent;
          border: 0;
          position: fixed;
        }
      }
      input {
        padding: 13px 10px;
      }
    }
  }
}

#jsn_form_1.jsn-master .jsn-bootstrap .jsn-form-content .control-group .controls input, #jsn_form_1.jsn-master .jsn-bootstrap .jsn-form-content .control-group .controls select, #jsn_form_1.jsn-master .jsn-bootstrap .jsn-form-content .control-group .controls textarea {
  background: #455059;
  color: #6A737A;
  border: 1px solid #6A737A;
}

.jsn-uniform.jsn-master .jsn-bootstrap .jsn-form-content .control-group {
  background-color: transparent !important;
  border-color: transparent !important;
  border: 0;
}

.jsn-master .jsn-bootstrap .control-group .select2-container.jsn-input-xlarge-fluid {
  width: 96%;
}

.jsn-uniform.jsn-master .jsn-bootstrap .jsn-form-content .control-group {
  margin-bottom: 15px;
}

#jsn_form_1.jsn-master .jsn-bootstrap .jsn-form-content .control-group.ui-state-highlight {
  background-color: transparent !important;
  border-color: transparent !important;
}

.padding-0 {
  padding: 0;
  .dropdown-menu {
    padding: 0;
  }
}

.btn.btn-white {
  border-color: #ffffff;
}

.btn-filled {
  &.btn-white {
    background-color: #ffffff;
    color: #2786c9;
    &:hover, &:focus {
      background-color: #1f6a9e;
      border-color: #1f6a9e;
      color: #ffffff;
    }
  }
}

.jsn-text-center {
  visibility: hidden;
  opacity: 0;
}

img {
  backface-visibility: hidden;
  height: auto;
  max-width: 100%;
}

.tz-gallery {
  position: relative;
  background: #000000 none repeat scroll 0 0;
  overflow: hidden;
  &:hover {
    .tz-title-gallery {
      filter: blur(0px);
      opacity: 1;
    }
    img {
      transform: scale(1, 1);
      opacity: 0.45;

      position: relative;
      width: 100%;
      z-index: 1;
    }
  }
  img {
    transition: all 0.5s ease-out 0s;
    transform: scale(1.05, 1.05);
    width: 100%;
  }
}

.tz-title-gallery {
  position: absolute;
  top: 50%;
  filter: blur(5px);
  left: 0;
  text-align: center;
  display: block;
  width: 100%;
  color: #ffffff;
  right: 30px;
  opacity: 0;
  transform: translate3d(0px, -50%, 0px);
  transition: all 0.5s ease-out 0s;
  z-index: 3;
}

h5, .h5 {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 18px;
  margin-bottom: 14px;
}

.sppb-person-image {
  .sppb-img-responsive {
    margin-bottom: 40px;
    border-radius: 50%;
  }
}

.sppb-person-name {
  color: #434343;
  font-size: 13px !important;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400 !important;
  line-height: 1.5;
}

.tz-image-Person {
  padding-bottom: 34px;
}

.tz-image-sections {
  .sppb-row {
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  //.sppb-addon-single-image-container {
  //  .sppb-img-responsive {
  //    box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.2);
  //    margin-left: 75px;
  //    max-width: inherit;
  //  }
  //}
}

.tz-infomation {
  color: #ffffff;
  span {
    a {
      color: #ffffff;
    }
  }

}

.tz-k2-content {
  h2 {
    margin-bottom: 0;

  }
  .tz-ItemIntrotext {
    p.lead {
      margin-top: 27px;
      color: #ffffff;
    }
  }
}

.tz-Modal {
  position: relative;
  height: 100vh;
}

.bg-slideshow {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.v-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  .translate(-50%, -50%);
  .transition(all 0.3s ease-out 0s);
  text-align: center;
  &.text-left {
    text-align: left;
  }
  &.text-right {
    text-align: right;
  }
}

.wrapper-video {
  .owl-carousel .owl-item img {
    height: 100vh;
  }
  h1 {
    color: #ffffff;
  }
}

.btn-play {
  color: #ffffff;
  display: inline-block;
  font-size: 22px;
  height: 72px;
  margin-bottom: 3px;
  position: relative;
  vertical-align: middle;
  width: 72px;
  &::before {
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
    border-radius: 50%;
    bottom: 0;
    box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.5);
    content: " ";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.3s ease-out 0s;
  }

  &::after {
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ffffff;
    border-style: solid;
    border-width: 13px 0 13px 23px;
    content: " ";
    filter: blur(0px);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0px);
  }
  &.btn-lg {
    height: 112px;
    width: 112px;
  }
}

.mb-30 {
  margin-bottom: 30px !important;
}

.animated {
  animation-delay: 0.1s;
}

.animated.visible {
  visibility: visible;
}

.h-md {
  height: 60vh;
}

.parallax-margin-0 {
  h1 {
    margin-bottom: 0;
  }
  p.lead {
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 21px;
  }
}

//.sppb-progress-bar {
//  border-radius: 30px;
//  text-align: right;
//  span {
//    line-height: 30px;
//    padding-right: 10px;
//  }
//}
//
//.sppb-progress {
//  border-radius: 40px;
//  height: 32px;
//}

.nav-black {
  .owl-prev, .owl-next {
    &:before{
      color: #434343 !important;
    }

  }
}

.owl-carousel {
  &:hover {
    .owl-nav {
      .owl-prev, .owl-next {
        opacity: 0.5;
      }

    }
  }
}

.tz-addon-box-slider {
  &.tz-black:hover {
    .owl-nav .owl-prev, .owl-nav .owl-next {
      color: #434343;
      opacity: 0.5;
    }
  }

  ul.owl-carousel {
    padding-left: 0 !important;
  }
  .even, .odd {
    padding: 0
  }
  &:hover {
    .owl-nav {
      opacity: 1 !important;
      .owl-prev {
        left: -25px;
      }
      .owl-next {
        right: -25px;
      }
    }
  }

}

.post-meta {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 20px 20px 0 20px !important;
  margin-bottom: 0;

  li {
    display: inline-block;
    padding-right: 15px;
    margin-bottom: 20px;
    font-size: 12px;

    a {
      color: #434343;
    }
    span {
      opacity: 0.5;
      display: block;
      line-height: 13px;
    }
  }
  padding-left: 0;

}

.addon-slider-IntroText {
  margin-bottom: 20px;
}

.box-slider-item {
  background: #ffffff;
}

.post-content {
  padding: 20px;
  h1 {
    font-size: 28px;
    margin-bottom: 20px;
    a {
      color: #434343;
    }

  }
}

.Testimonials-list {
  .quote {
    font-size: 30px;
    font-weight: 100;
    line-height: 1.2;
    margin-bottom: 25px;
  }

  .author {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    .name {
      display: block;
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .author.with-image {
    padding-left: 74px;
    position: relative;
    padding-top: 25px;
    padding-bottom: 15px;
    img {
      border-radius: 50%;
      height: 58px;
      left: 0;
      position: absolute;
      top: 18px;
      width: 58px;
    }
    span {
      &.text-muted {
        color: rgba(255, 255, 255, 0.5);
      }
      display: block;
      color: #ffffff;
      a {
        color: #ffffff;

      }
    }
  }
}

.text-muted {
  color: rgba(0, 0, 0, 0.4);
}

.zooming {
  animation: 18s ease 0s normal both infinite running zooming;
}

.bg-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.hiden-relative {
  .sppb-column-addons, .sppb-column {
    position: static;
  }
  .sppb-row > div {
    position: static;
  }
}

.bg-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  animation: 18s ease 0s normal both infinite running zooming;
}

.tz-Overlay {
  position: relative;
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1;
    content: "";
    top: 0;
    left: 0;
  }
}

.gray-to-color {
  filter: grayscale(1);
  opacity: 0.7;
  transition: all 0.3s ease-out 0s;
}

.testimonial {
  &.dark{
    .testimonial-2{
      .quote{
        &:after {
          border-color: rgba(0, 0, 0, 0) #111111 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
        }
      }
    }
  }
  .testimonial-2.testimonial-primary {
    .quote {
      background-color: #2786c9;
      color: #ffffff;
      &::after {
        border-color: rgba(0, 0, 0, 0) #2786c9 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
      }
    }

  }
  .testimonial-2.testimonial-danger {
    .quote {
      background-color: #cf474f;
      color: #ffffff;
      &::after {
        border-color: rgba(0, 0, 0, 0) #cf474f rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
      }
    }

  }
  .testimonial-2 {
    margin-bottom: 30px;
    .author {
      line-height: 1.2;
      margin-top: 20px;
      text-align: left;
      .name {
        font-weight: 600;
        font-size: 14px;
      }
    }
    .author.with-image {
      margin-top: 30px;
      padding-left: 74px;
      position: relative;
      img {
        border-radius: 50%;
        height: 58px;
        left: 0;
        position: absolute;
        top: -12px;
        width: 58px;
      }
    }
    .quote {
      background-color: #f6f6f6;
      border-radius: 3px;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.2;
      padding: 25px 30px;
      position: relative;
      &:after {
        border-color: rgba(0, 0, 0, 0) #f6f6f6 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
        border-style: solid;
        border-width: 0 38px 38px 0;
        content: " ";
        height: 0;
        position: absolute;
        right: 20px;
        top: 100%;
        transform: scaleX(0.4);
        width: 0;

      }
    }
  }
  .owl-theme {
    div.owl-dots {
      margin: 0;
      .owl-dot {
        &:hover,
        &.active {
          span {
            opacity: 0.6;
          }
        }
        span {
          background: #000000;
          opacity: 0.2;
        }
      }
    }
    div.owl-nav {
      [class*=owl-] {
        .transition(all 0.2s ease-out);
        opacity: 0;
        padding: 0;
        margin: 0;
        top: 50%;
        margin-top: -20px;
        visibility: hidden;
        &:hover {
          opacity: 1 !important;
        }
      }
    }
    &:hover {
      div.owl-nav {
        [class*=owl-] {
          opacity: 0.5;
        }
        .owl-prev {
          left: -35px;
        }
        .owl-next {
          right: -35px;
        }
      }
    }
  }
}

.dark {
  .testimonial-2 .quote {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.Background-blue {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background: rgba(39, 134, 201, 0.7);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.tz-addon-content-video {
  position: relative;
  overflow: hidden;
  .image-content {
    z-index: 1;
    .btn-play {
      cursor: pointer;
      position: absolute;
      left: 50%;
      top: 50%;
      .translate(-50%, -50%);
    }
  }
  .tz-addon-video {
    z-index: 0;
  }
}

.bg-white {
  .sppb-row-container {
    background: #ffffff;
  }
  .content {
    padding: 70px;
  }
  p.lead {
    margin-top: 29px;
  }
}

.navbar-nav {
  float: right;
}

@media (min-width: 768px) {
  .navbar-nav {
    float: right;
  }
}

.breadcrumb > li + li {
  &:first-child {
    &:before {
      content: '';
    }
  }

}

.breadcrumb {
  background: transparent;
  display: block;
  text-align: right;
  text-transform: uppercase;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 11px;
  margin-bottom: 0;
  padding: 0;
  line-height: 40px;
}

.page-title {
  h1 {
    font-size: 36px;
    letter-spacing: 0.2em;
    margin-bottom: 0;
    text-transform: uppercase;
    text-align: left;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
  }

}

#tzlogo {
  cursor: pointer;
  position: relative;
  max-height: 68px;
  line-height: 68px;
  display: inline-block;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  z-index: 6;
  height: 68px;
  img {
    position: relative;
    top: -2px;
    max-height: 50%;
  }
}

.dropdown-submenu {
  > div.dropdown-menu {
    margin-left: 0;
    margin-top: 0;
  }
}

body .plazart-megamenu .mega-nav > li a:hover,
.plazart-megamenu .dropdown-menu .mega-nav > li a:hover,
body .plazart-megamenu .mega-nav > li a:focus,
body .plazart-megamenu .dropdown-menu .mega-nav > li a:focus {
  background: transparent !important;
}

body .plazart-megamenu .dropdown-menu .mega-nav {
  //padding: 10px 0;

  .mega-inner {
    //padding: 8px 0;
  }
  .mega-nav {
    padding: 5px 0;
  }
}

div.plazart-megamenu {
  &.animate.elastic .level0 > .mega-align-center.open > .mega-dropdown-menu {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  }
  div.mega-nav > li a, div.dropdown-menu .mega-nav > li a {
    font-size: 11px;
    font-weight: 400;
    padding: 15px 16px;
    i {
      margin-right: 8px;
    }
  }
  .mega-align-justify,
  .mega-align-center {
    .dropdown-menu {
      .mega-nav {
        &.level1 {
          padding: 25px 0 !important;
        }
      }
    }
  }
  .dropdown-menu {
    .mega-nav {
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);

      .mega-group-title {
        opacity: 0.5;
        padding-top: 0;
        -webkit-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -o-transform: none !important;
        transform: none !important;
        &:hover {
          color: inherit;
        }
      }
      li {
        a {
          .transition(all 0.2s ease-in-out);
          &:hover {
            -webkit-transform: translateX(5px);
            -moz-transform: translateX(5px);
            -ms-transform: translateX(5px);
            -o-transform: translateX(5px);
            transform: translateX(5px);
            color: #2786c9;
          }
        }
      }
    }
    .dropdown-menu {
      top: 0 !important;
      border-top: 0;
    }
  }
  .mega-align-center .mega-dropdown-inner {
    padding-left: 30px;
  }
  .mega-align-justify, .mega-align-center {
    .mega-nav {
      box-shadow: none;
    }
    div.dropdown-menu {
      .row .mega-nav > li {
        border-bottom: 0;
      }
    }
    div.mega-nav > li a, div.dropdown-menu .mega-nav > li a {
      padding: 6px 0;
    }

  }
  div.dropdown-menu {
    .row {
      margin-right: -15px;
      margin-left: -15px;
      .mega-nav > li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
  div.mega-inner {
    padding: 0;
  }
  .nav > .dropdown > {
    .dropdown-toggle {

      &:after {

        font-family: themify;
        font-size: 80%;
        content: "\e62a";
        margin-left: 9px;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
        opacity: 0.5;
        -moz-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
      }


      .caret {
        display:  none;
      }
    }
  }
}

ul.level0 li {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  display: block !important;
  &.search_icon {
    opacity: 0.5;
  }
  &.mega-align-justify .dropdown-menu {
    > .mega-dropdown-inner {
      padding-left: 60px;
      padding-right: 15px;
    }
  }
}

.navbar {
  border: 0;
  margin: 0;
  padding: 0;
  position: static;
}

.navbar-nav > li > a, .search_icon, .shopping_icon {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 68px;
}

.full-menu {
  position: static;
  .plazart-mainnav {
    position: static;
  }
}

.search_icon, .shopping_icon {
  padding: 0 15px;
  display: block;
  opacity: 0.5;
  .transition(all 0.2s ease-out);
  &:focus, &:active, &:hover {
    opacity: 1;
    color: #434343;
  }
  i {
    font-size: 16.9px;
    cursor: pointer;
  }
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent;
}

.tz-bd-botom {
  position: relative;
  -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1);
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1);
  z-index: 1;
}

.tz-top-seach {
  position: static;
}

.tz-top-seach.open .tz_search_menu {
  opacity: 1;
  visibility: visible;
}

.tz_search_menu {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 0;
  visibility: hidden;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 50px;
  padding-right: 50px;
  height: 68px;
  z-index: 9;
  background: #fff;
  -moz-transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
  -webkit-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;

  .inputbox {
    width: 100%;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    height: 68px;
    font-size: 20px;
    border: none;
    background: transparent;
    padding-right: 90px;
  }
  .search-bar-submit {
    position: absolute;
    top: 15px;
    right: 74px;
    border: none;
    background: transparent;
    font-size: 24px;
    color: #2786c9;
  }
  .search-bar-close {
    position: absolute;
    top: 24px;
    right: 46px;
    font-size: 13px;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
  }
}

.dropdown-menu {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.176);
  padding: 0;
  .mega-col-module {
    overflow: hidden;
  }
}

.list-posts {
  padding: 0;
  > li {
    margin-bottom: 15px;
    a {
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      font-size: 13px;
      &:after {
        position: relative;
        font-family: 'themify';
        width: 15px;
        height: 15px;
        background: rgba(0, 0, 0, 0.2);
        color: #fff;
        content: "\e628";
        margin-left: 5px;
        padding: 2px;
        text-align: center;
        vertical-align: middle;
        font-size: 8px;
        display: inline-block;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
      }
    }
    span.moduleItemDateCreated {
      opacity: 0.7;
      font-size: 12px;
    }
  }
}

.widget-logo {
  .logo {
    margin: 60px auto;
    max-width: 35%;
    text-align: center;
  }
  h6 {
    font-size: 12px;
    margin-bottom: 25px;
  }
}

.icon.icon-square.icon-xs, .icon.icon-circle.icon-xs {
  font-size: 13px;
  height: 36px;
}

.icon {
  display: inline-block;
  font-size: 52px;
  margin-bottom: 3px;
  position: relative;
  vertical-align: middle;
  width: 72px;

  &.icon-white {
    background: #ffffff;
  }
  &.icon-lg {
    font-size: 82px;
    width: 112px;
  }
  &.icon-circle, &.icon-square {
    color: #ffffff;
    font-size: 32px;
    height: 72px;
    text-align: center;
    &.icon-lg {
      height: 112px;
      font-size: 48px;
    }
    &.icon-sm {
      height: 48px;
      width: 48px;
      em.ti-files.text-primary {
        margin-right: -17px;
        line-height: 48px;
      }
    }
    i {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate3d(-50%, -50%, 0px);
    }
  }
  &.icon-xs {
    font-size: 16px;
    width: 36px;
  }
  &.icon-circle {
    border-radius: 50%;
    margin-bottom: 15px;
  }
  &.icon-square, &.icon-circle {
    &.tzicon-facebook {
      background-color: #213553;
    }
    &.tzicon-twitter {
      background-color: #3aa8db;
    }
    &.tzicon-google-plus {
      background-color: #d04f3e;
    }
  }

}

.widget_okno_logo {
  h6 {
    color: #ffffff;
  }
}

.tz-footer {

  h3.module-title {
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 25px;
  }
}

.footer {
  font-size: 12px;
}

//.tz-content_tweet {
//  overflow: hidden;
//  color: #fff;
//  font-size: 12px;
//  text-align: left;
//  .tztwd-tweet-data {
//    font-size: 10px;
//    text-align: left;
//    a {
//      color: #c1c1c1 !important;
//    }
//  }
//  .tztwd-tweet {
//    a {
//      color: #a6bac1 !important;
//    }
//
//  }
//}

.tztwd-avatar {
  width: 45px !important;
}

.mc-field-group {
  input {
    color: #000000;
    padding: 10px 20px;
    border-radius: 30px;
    width: 100%;
    border: 0;
  }
}

.btn-submit {
  display: block;
  text-align: center;
  width: 100% !important;
  background-color: transparent !important;
  border: none;

  > * {
    position: relative;
    z-index: 2;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    &:hover, &:active {
      color: #ffffff;
    }
  }
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 15px;
    padding-top: 9px;
    background-color: #2786c9;
    border: 2px solid #2786c9;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    content: ' ';
  }
}

.mc-field-group {
  .required.email {
    height: 38px;
    margin-bottom: 3px;
  }
}

.max-email-box {
  margin-bottom: 44px;
}

.contact-address.style2 {
  font-size: 12px;
}

.wrapper-a {
  &.tz-contact-address {
    padding: 18px 0 0;;
  }
  padding: 14px 0 0;
}

.text-right {
  position: static;
}

.banner.h-300 {
  height: 330px !important;
}

.banner {
  .v-center {
    position: absolute;
  }
}

.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
  background: transparent;
  border: 0;
  border-color: transparent;
}

.container-fluid {
  background: transparent !important;
}

//footer
.menu-footer-container {

  .nav-inline.nav-sm a {
    font-size: 11px;
  }
  ul {
    li {
      display: inline-block;
      a {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        font-family: "Montserrat", Helvetica, Arial, sans-serif;
        opacity: 0.5;
        padding: 0 9px;
        text-transform: uppercase;
      }
    }
  }
}

.sep-line {
  background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
  border: medium none;
  display: block;
  height: 1px;
  margin: 0;
  position: relative;
  z-index: 1;
  &.dark {
    background: rgba(255, 255, 255, 0.2) none repeat scroll 0 0;
  }
}

.v-bottom {
  padding: 0;
  li {
    display: inline-block;
  }
}

.slider_agency {
  height: 100vh;
  text-align: center;
  .sppb-row-container {
    height: 100%;
  }
  .sppb-row {
    height: 100%;
  }
  .sppb-col-md-12:first-child {
    height: 100%;
  }
  .div[class*="sppb-col-"] {
    height: 100%;
  }
}

.tz-0-social {
  li {
    padding: 0 5px;
  }
}

.list-lined {
  li {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
    list-style: outside none none;
    margin-bottom: 8px;
    padding-bottom: 8px;
    &:last-child {
      border-bottom: 0;
    }
  }
}

.img-auto {
  img {
    width: auto !important;
    max-width: none !important;
    margin: 0 auto;
  }
}

.tz-content-center {
  height: 100vh;

  > .sppb-column-addons {
    top: 50%;
    position: absolute;
    transform: translate3d(0px, -50%, 0px);
  }
}

.btn-40center {
  margin: -40px auto 0;
  width: 30%;
}

.btn-center {
  margin: 0 auto;
}

.f-height {
  .sppb-img-responsive {
    height: 100%;
  }
}

.sppb-addon-animated-number .sppb-addon-content .sppb-animated-number-title {
  margin-top: 11px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

.w-50 {
  width: 50%;
}

.h5-b3 {
  h5 {
    margin-bottom: 3px;
  }
}

.icon.icon-primary {
  color: #08c9d5;
}

.icon.icon-sm {
  font-size: 28px;
  width: 48px;
}

.feature {

  display: block;
  .sppb-addon-single-image-container {
    position: static;
  }
  &.feature-1 {
    .tz-icon {
      margin-bottom: 16px;
      color: #08c9d5;
    }
  }
  &.feature-5 {
    position: relative;
    height: 450px;
    padding: 40px;
    overflow: hidden;
    text-align: center;
    .sppb-addon-content {
      height: 100%;
      div.image {
        height: 100%;
        .sppb-addon-html {
          height: 100%;
        }
      }
    }
    img {
      mix-blend-mode: multiply;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 0;
    }
    .content {
      position: relative;
      top: 50%;
      -moz-transform: translate3d(0, -50%, 0);
      -webkit-transform: translate3d(0, -50%, 0);
      transform: translate3d(0, -50%, 0);
      .transition(all .3s ease-out);
      z-index: 1;
    }
  }
}

.bg-grey-dark {
  background-color: #787878;
}

.bg-grey-blue {
  background-color: #0f4fb9;
}

.p-20 {
  padding: 20px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-63 {
  margin-top: 63px;
}

.sppb-col-p0 [class*="sppb-col"] {
  padding-left: 0;
  padding-right: 0;
  .sppb-addon-single-image-container {
    display: flex;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.bg-black {
  background-color: #000;
}

.image-box {
  position: relative;
  overflow: hidden;
  &.image-hover:hover .image .hover img, &.image-hover:hover .image .hover a > img {
    filter: alpha(opacity=45);
    opacity: .45;
  }
  &.image-hover {

    .image {
      img {
        -moz-transform: scale(1.05, 1.05);
        -ms-transform: scale(1.05, 1.05);
        -webkit-transform: scale(1.05, 1.05);
        transform: scale(1.05, 1.05);
      }
    }
  }
  &.image-hover:hover {
    .image {
      img {
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0.45;
      }
    }
  }
  &:hover {
    .hover {
      filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
      opacity: 1;
      -webkit-filter: blur(0);
      filter: blur(0);
    }
    img {
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  .image {
    position: relative;
    background: #000;
    overflow: hidden;
    img {
      position: relative;
      -moz-transition: all .5s ease-out;
      -o-transition: all .5s ease-out;
      -webkit-transition: all .5s ease-out;
      transition: all .5s ease-out;
      width: 100%;
      z-index: 1;
    }
  }
  .hover {
    position: absolute;
    top: 50%;
    left: 30px;
    right: 30px;
    -moz-transform: translate3d(0, -50%, 0);
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    -moz-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    -webkit-transition: all .5s ease-out;
    transition: all .5s ease-out;
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-filter: blur(5px);
    filter: blur(5px);
    color: #fff;
    z-index: 3;
    .text-muted {
      color: rgba(255, 255, 255, .5);
    }
  }
}

.owl-carousel .owl-item {
  overflow: hidden;
  img {

    width: auto !important;
    margin: 0 auto;

  }
}

.section#clients h6 {
  font-size: 30px;
  font-weight: 100;
  letter-spacing: 0;
  text-transform: none;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.bg-dark {
  background-color: #1f1f1f;
}

.pt120 {
  padding-top: 120px !important;
}

.section-lg {
  position: relative;
  overflow: hidden;
}

.tz-content-map {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

}

.bg-map {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.dark-overlay {
  &::after {
    background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s ease-out 0s;
    width: 100%;
  }
}

.dark .sppb-addon-gmap-canvas {
  position: relative;
  &:after {
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    .transition(all 0.3s ease-out 0s);
    width: 100%;
  }
  &:hover {
    &:after {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    }
  }
}

.feature.feature-2 {
  .icon {
    float: left;
  }
}

.icon {
  display: inline-block;
  font-size: 52px;
  margin-bottom: 3px;
  position: relative;
  vertical-align: middle;
  width: 72px;
  .icon-sm {
    font-size: 28px;
    width: 48px;
  }
  .icon-primary {
    color: #cf474f;
  }
}

.icon-primary {
  color: #cf474f;
}

.feature.feature-2 .icon.icon-sm + .feature-content {
  padding-left: 55px;
}

.feature.feature-2 .feature-content {
  padding-left: 75px;
  padding-top: 10px;
}

.h-lg {
  height: 80vh;
  > div {
    position: relative;
    top: 50%;
    transform: translate3d(0px, -50%, 0px);
    transition: all 0.3s ease-out 0s;
    text-align: center;
  }
}

ul.nav-pills {
  > li {
    > a {
      border-radius: 4px;
      float: left;
      opacity: 0.5;
    }

  }
}

.magin--50px {
  img {
    margin-bottom: -50px;
    visibility: visible;
    z-index: 9;
    position: relative;
  }

}

.text-lg {
  h2.sppb-person-designation {
    font-size: 58px;
  }

}

//.tztwd-tweet-container {
//  position: relative;
//  margin-bottom: 35px;
//  .avata-info {
//    position: absolute;
//    display: inline-block;
//
//  }
//  .tz-content_tweet {
//    margin-left: 50px;
//  }
//}

.subscribe {
  .max-email-box {
    margin: 0;
  }
  .mc-field-group {
    width: 66%;
    float: left;
    margin-right: 4%;
    input.required.email {
      height: 54px;
    }
  }
  .clear {
    width: 30%;
    float: left;
    button {
      padding: 17px 32px 17px;
    }
    .btn-submit:after {
      background: #2e9557;
      border-color: #2e9557;
    }
  }
}

.section-image-edge {
  .container {
    position: relative;
    div[class*="col-"] {
      position: absolute;
      top: 50%;
      .translate(0, -50%);
    }
  }
  &.owl-carousel {
    .owl-item {
      .right {
        text-align: right;
      }
      img {
        margin: 0;
        display: inline-block;
      }
    }
  }
  .tz__title {
    font-size: 58px;
    margin-bottom: 22px;
  }
  .tz__image {
    padding: 0;
  }
}

ul.tz-clients {
  li {
    display: inline-block;
    margin: 0 20px;
    .tz-image {
      width: auto;
    }
    img {
      cursor: pointer;
      filter: grayscale(1);
      &:hover {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }
}

.tz-homeType2 {
  .tz-logo-image2 {
    opacity: 0;
    overflow: hidden;
    height: 0;
  }
  &.out {
    -moz-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
  }
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 99;
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  &.crt-top.fixed {
    background: #ffffff;
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    &.tz_fix_menu_on_slider a.tz-open {
      color: #434343;
    }
    .tz-logo-image1 {
      opacity: 0;
      overflow: hidden;
      height: 0;
    }
    .tz-logo-image2 {
      opacity: 1;
      overflow: visible;
    }
    .plazart-megamenu ul li a {
      color: #434343;
    }
  }
  .search_icon, .shopping_icon {
    color: #ffffff;
  }
  .cart_module .notification {
    bottom: -1px;
    //left: 15px;
    height: 17px;
    width: 17px;
    border: none;
  }
  &.fixed {
    .search_icon, .shopping_icon {
      color: #434343;
    }
  }
}

.tz-main-menu-wrapper {
  position: fixed;
  top: 0;
  height: 100%;
  width: 50vw;
  z-index: 999999;
  visibility: hidden;
  filter: alpha(opacity=0);
  opacity: 0;
  .transition(all .4s ease-out);
  .box-shadow(0 0 40px 0 rgba(0, 0, 0, .2));
  text-align: center;
  &.right {
    right: 0;
    .translate(50px, 0);
  }
  > a {
    position: absolute;
    top: 20px;
    right: 116px;
    font-size: 21px;
    &.tz-close {
      visibility: hidden;
      opacity: 0;
    }
  }
  .tz-menu-panel {
    position: absolute;
    top: 50%;
    .translate(0, -50%);
    left: 0;
    right: 0;
    padding: 0;
  }
}

#side-panel {
  .close {
    position: absolute;
    top: 25px;
    right: 65px;
    color: #fff;
    text-shadow: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    opacity: 0.4;
    .transition(all 0.2s ease-out);
    &:hover {
      opacity: 1;
    }
  }
  .tz-open {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity: 0.5;
    .transition(all 0.2s ease-out);
    cursor: pointer;
    display: inline-block;
    height: 68px;
    line-height: 68px;
    padding: 0 15px;
    &:hover {
      color: inherit;
      opacity: 1;
    }
    i {
      font-size: 130%;
    }
  }
}

.side-panel-open {
  .tz_fix_menu_on_slider {
    a.tz-open {
      visibility: hidden;
      opacity: 0;
    }
  }
  .tz-main-menu-wrapper {
    opacity: 1;
    visibility: visible;
    &.right {
      .translate(0, 0);
    }
    > a {
      &.tz-close {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.tz__header_style_1 {
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 999999;
  background: #ffffff;
  padding: 0 35px;
}

.background_transparent {
  background: transparent;
}

.box--shadow_none {
  .box-shadow(none);
}

.tz__header {
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 999999;
  background: #ffffff;
  padding: 0 35px;
  .box-shadow(0px 2px 3px 0px rgba(0, 0, 0, 0.1));
  &.absolute {
    position: absolute;
  }
  &:not(.out).tz__header--transparent {
    background: transparent;
    .box-shadow(none);
    color: #fff;
  }
  &.fixed {
    position: fixed;
    .translate(0, -100%);
    visibility: hidden;
    opacity: 0;
  }
  &.out {
    visibility: visible;
    opacity: 1;
    .transition(all 0.3s ease-out);
    &.sticky {
      .translate(0, 0);
      .cart_module .notification {
        .translate(-50%, 0);
      }
    }
    .cart_module .notification {
      .translate(-50%, -4px);
    }
  }
}

.tz__header--transparent {
  &.out {
    .logo-light {
      display: none;
    }
    .plazart-megamenu ul li a {
      color: #434343;
    }
    .search_icon, .shopping_icon {
      color: #434343;
    }
  }
  &:not(.out) {
    .logo-dark {
      display: none;
    }
    .plazart-megamenu ul li a {
      color: #ffffff;
    }
    .search_icon, .shopping_icon {
      color: #ffffff;
    }
    .cart_module .notification {
      bottom: -1px;
      height: 17px;
      width: 17px;
      border: none;
    }
  }

}

.tz_fix_menu_on_slider {
  a.tz-open {
    margin: 20px 49px;
    position: relative;
    display: inline-block;
    font-size: 16.9px;
    color: #ffffff;
    opacity: 0.45;
  }

}

ul.nav-pills.main-menu-agency {
  margin-bottom: 50px;
  font-size: 12px;
  > li {
    padding: 0;
    width: 100%;
    > a {
      float: none;
      font-family: "Montserrat", Helvetica, Arial, sans-serif;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      opacity: 0.6;
      color: #ffffff;
      padding: 10px 15px;
      .transition(all 0.2s ease-in-out);
      &:hover {
        opacity: 1;
      }
    }
  }
}

.Social_blog_bg_meetup {
  ul {
    li {
      a {
        color: #ffffff;
        height: 36px;
        width: 36px;
        display: inline-block;
        border-radius: 3px;
        position: relative;
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          -moz-transform: translate3d(-50%, -50%, 0);
          -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
        }
      }
    }
  }
}

.div.itemCommentsForm form span#formLog.k2FormLogError {
  background: transparent;
}

.tz-about-app {
  padding: 76px 0 0 80px;
}

.tz-about-app2 {
  padding: 135px 0 0 80px;
}

.right-menu {
  float: right;
  > div {
    float: right;
  }
}

.cart_module {
  &.z-999 {
    z-index: 1;
  }
  z-index: 1001;
  position: relative;
  .notification {
    background: #e15757 none repeat scroll 0 0;
    text-align: center;
    font-size: 11px;
    color: #ffffff;
    border-radius: 50%;
    border: 3px solid #ffffff;
    bottom: -4px;
    font-weight: 600;
    height: 23px;
    left: 50%;
    position: absolute;
    width: 23px;
    z-index: 1;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    .translate(-50%, 0);
  }
  .cart-popup {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
    background: #ffffff none repeat scroll 0 0;
    line-height: 1;
    padding: 10px;
    position: absolute;
    text-align: left;
    width: 250px;
    z-index: 2;
    right: 0;
    .popup {
      display: inline-block;
    }
    .hikashop_small_cart_clean_link {
      float: right;
    }
  }
  .tz-popup-item {
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.k2AccountPage th.k2ProfileHeading {

  background: transparent;
}

.k2AccountPage table.admintable td.key, .k2AccountPage table.admintable td.paramlist_key {
  background: transparent;
  border-bottom: 0;
  border-right: 0;
}

.admintable {
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 30px;
  span.key {
    display: block;
  }
  h2, .k2ProfileHeading, .key, #genderm-lbl, #image, .k2AccountPageNotice, #genderf-lbl {
    color: #ffffff;
  }
  .inputbox {
    -moz-appearance: none;
    background-color: #ffffff;
    border: 1px solid #ededed;
    border-radius: 3px;
    box-shadow: none;
    font-size: 13px;
    height: 46px;
    margin-bottom: 3px;
    padding: 15px 20px;
    width: 100%;
  }
  .radio {
    display: inline-block;
    margin: 0 15px 0 0;
    input[type="radio"] {
      top: 2px;
      margin-right: 3px;
    }
  }
  .CodeMirror {
    max-height: 100px;
  }
  .tz_imagemsg,
  .tz_gendermsg {
    display: inline-block;
  }
  .tz_imagemsg {
    float: right;
  }
}

.checkbox + .checkbox, .radio + .radio {
  padding-top: 0px
}

.componentheading {
  h2 {
    text-align: left;
  }
}

.checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"], .radio input[type="radio"], .radio-inline input[type="radio"] {
  position: relative;
  margin-left: 0;
}

body .select2-container .select2-choice div b {

  background: transparent !important;
}

.tz_custom_pd_lr_210 {
  padding: 0 210px 0 210px;
}

.tz_custom_pd_rl300 {
  padding: 0 300px 0 300px;
}

.tz_custom_pd_rl200 {
  padding: 0 200px 0 200px;
}

.tz_custom_mr_rl300 {
  margin: 0 300px 0 300px;
}

.tz_custom_mr_rl150 {
  margin: 0 150px 0 150px;
}

/* Css Global*/

.tz__block_full--height {
  height: 100vh;
}

.tz__flex--center {
  align-items: center;
  justify-content: center;
  display: flex;
  //text-align: center;
}

.tz__block--absolute {
  position: absolute;
}

.tz__block--absolute--fullWidth {
  left: 0;
  width: 100%;
}

.tz__bottom--25 {
  bottom: 25px;
}

.tz__flex {
  display: flex;
  &.tz__flex--center {
    justify-content: center;
  }
}

.tz__width--50per {
  width: 50%;
}

.tz__width--100per {
  width: 100%;
}

/* Zooming */
.zooming {
  -moz-animation: zooming 18s infinite both;
  -webkit-animation: zooming 18s infinite both;
  animation: zooming 18s infinite both;
}

@-moz-keyframes zooming {
  0% {
    -moz-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -moz-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -moz-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-webkit-keyframes zooming {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes zooming {
  0% {
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

/* Blinking */
.blinking {
  -moz-animation: blinking 2s infinite both;
  -webkit-animation: blinking 2s infinite both;
  animation: blinking 2s infinite both;
}

@-moz-keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sppb-instagram-images {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.sppb-addon-instagram-gallery .sppb-instagram-images li a:after {
  top: 50%;
  bottom: auto;
  right: 0;
  left: 0;
  .translate(0, -50%);
  height: auto;
  font-size: 24px;
  content: '' !important;
}

.tz_padding_item--1per {
  ul {
    margin: -1% !important;
    li {
      padding: 1%;
    }
  }
}

.sppb-addon-instagram-gallery .sppb-instagram-images li .sppb-instagram-gallery-btn:hover:after {
  .translate(0, -50%);

}

.sppb-addon-instagram-gallery .sppb-instagram-images li .sppb-instagram-gallery-btn:hover {
  .scale(1);
}

.tz__block--overlay {
  &.sppb-addon-instagram-gallery .sppb-instagram-images li a:before {
    opacity: 1;
    visibility: visible;
    background: rgba(0, 0, 0, 0.7);
  }
}

.sppb-addon-instagram-gallery .sppb-instagram-images li .sppb-instagram-gallery-btn:hover:before {
  opacity: 0.6;
}

body {
  .mfp-bg, .mfp-wrap {
    z-index: 9999999;
  }
}

.section-image-edge {
  .item_multiagency {
    .image {
      padding: 0;
    }
    .tzbg-image {
      background-size: cover;
      background-position: center;
      background-attachment: scroll;
      background-repeat: no-repeat;
      padding: 0 0 40px;
      img {
        margin-bottom: 40px !important;
      }
    }
  }
}

//tz__block__img
.tz__block__img {
  .image {
    position: relative;
  }
  .tz__block__content {
    position: absolute;
    top: 50%;
    .translate(0, -50%);
    left: 0;
    right: 0;
    z-index: 1;
    .transition(all 0.3s linear 0s);
  }
  .tz__block__img {
    img {
      mix-blend-mode: multiply;
    }
  }
  .tz__block__icon--overlay,
  .tz__block--overlay {
    z-index: 1;
  }
}

.tz__block__image--lightbox {
  &:hover {
    .tz__block__content {
      .scale(0);
      opacity: 0;
    }
  }
}

//background page - register, login
.tz_bg {
  background-repeat: no-repeat !important;
  background-attachment: scroll !important;
  background-size: cover !important;
  background-position: center !important;
}

.mg-lr-pull-lr-15 {
  margin: 0 -15px;
}
body .plazart-megamenu .dropdown-submenu.mega-align-right > .dropdown-menu {
  left: 100%;
  right: auto;
}

.btn-link{
  border: 0;
}

body .hikashop_product_characteristics_table{
  tr{
    td:first-child{
      width: 10%;
    }
  }
  .chzn-container .chzn-drop{
    width: auto;
  }
  .chzn-container-single{
    .chzn-single{
      padding-left: 15px;
      li{
        margin: 0 15px;
      }
      div{
        right: 13px;
      }
    }
  }
}

.hikashop_products_pagination,.tzPagination,.k2Pagination{
  i{

    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.15);
    width: 20px;
    height: 20px;
    font-size: 12px;
    color: #fff;
    padding: 6px;
    &.icon-previous{
      font-family: themify;
      speak: none;
      font-style: normal;
      font-weight: 400;
      &:before{
        content: "\e629";
      }
    }
    &.icon-next{
      font-family: themify;
      speak: none;
      font-style: normal;
      font-weight: 400;
      &:before{
        content: "\e628";
      }
    }
  }

}